export const AMC_PLANS = ["Annual Maintenance Contract", "Comprehensive AMC"]

export const CROSSELLS = {
    "amc": [{
        'title': "Book Instant Repair & Maintenance Services for your Water Purifiers",
        'descriptions': ["High Quality Repairs, 90 Day post-service warranty", "Complete door to door service by top quality engineers"],
        'target_url': "ro-water-purifier-service-repair",
        'priority': 2
    },
    {
        'title': 'Annual Service Plans to Keep Your AC Running',
        'descriptions': ['2 Free Preventive Maintenance Services', 'Free Gas Charging & unlimited repairs including spares'],
        'target_url': "ac-service-repair",
        'priority': 1
    }],
    "ew": [{
        "title": "Spills & Drops Protection Plan for your Mobile Phones.",
        "descriptions": [
            "Any Physical and Liquid damage covered",
            "Free Pick & Drop, Completely Paperless process"
        ],
        "target_url": "mobile-phones-damage-protection-plans"
    },
    {
        "title": "Screen Protection Plan for your Mobile Phones.",
        "descriptions": [
            "Broken, Cracked, Shattered Screens Covered",
            "Free Pick & Drop, Completely Paperless process"
        ],
        "target_url": "mobile-phones-screen-protection-plans"
    }, {
        "title": "Extend Manufacturer warranty of your Mobile Phones.",
        "descriptions": [
            "Covers Malfunctions & Breakdowns",
            "Free Pick & Drop, Completely Paperless process"
        ],
        "target_url": "mobile-phones-extended-warranty-plans"
    }]
}

export const FIELDS = {
    base_fields : ['brand', 'model', 'device_price', 'device_purchased_date', 'manufacturer_warranty', 'device_condition', 'serial'],
    amazon : ['brand', 'model', 'device_price', 'device_purchased_date', 'manufacturer_warranty', 'device_condition', 'serial', 'device_name'],
    croma : ['preferred_plan_start_date', 'brand', 'device_purchased_date', 'manufacturer_warranty','device_name']
}

export const CHANNELS = ['amazon', 'croma']

export const SERIAL_NUMBER_CATEGORY = ['all-in-one-pc'] 

export const SERIAL_NUMBER_CATEGORY_TYPES = ['laptop', 'mobile phone', 'tablet', 'television']

export const DROPDOWN_ADDING_NAMES = {
    "brand" : "brand", 
    "device_name" : "device",
    "Manufacturing warranty": "manufacturing warranty",
    "Device Condition": "device condition"
  
  }