export const LOGIN_CUSTOMER = 'LOGIN_CUSTOMER'

export const SET_CURRENT_STEP_CUSTOMER = 'SET_CURRENT_STEP_CUSTOMER';

export const SET_CUSTOMER_DETAILS = 'SET_CUSTOMER_DETAILS';

export const SET_IS_AUTHENTICATE = 'SET_IS_AUTHENTICATE';

export const SET_SIGNOUT_ALERT = 'SET_SIGNOUT_ALERT';

export const SET_CUSTOMER_CART_ID = 'SET_CUSTOMER_CART_ID'

export const CREATE_NEW_ADDRESS = 'CREATE_NEW_ADDRESS'

export const UPDATE_ADDRESS = 'UPDATE_ADDRESS'

export const UPDATE_CART_EDIT_MODE = 'UPDATE_CART_EDIT_MODE'

export const SIGNOUT = 'SIGNOUT'

export const SET_CUSTOMER_PHONE_PREFILLED = 'SET_CUSTOMER_PHONE_PREFILLED'
