import { PushToTracker  } from "../../utils/tracking";

// data contains - { cart_total, sku_in_cart, utm_params }
export const cartViewed = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Cart Viewed', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {}
export const resendOtpClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Resend OTP Clicked', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { 'status':success or failed,}
export const otpVerifiedSegment = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'OTP Verified', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { full_details }
export const enterPersonalDetailsAutopopulate = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Enter Personal Details Autopopulate', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { full_details }
export const enterPeronsalDetailsModified = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Enter Peronsal Details Modified', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { }
export const confirmClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Personal Details Confirm Clicked', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { source, category_name, pincode }
export const slotSelectionAllSlotsSelected = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Slot Selection - All Slots Selected', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { source, category_name, pincode }
export const slotSelectionNoSlotsAvailable = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Slot Selection - No Slot Available', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { source, category_name, pincode }
export const slotSelectionViewed = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Slot Selection Viewed', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {}
export const viewCertificateClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'View Certificate Clicked', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {}
export const invoiceDownloadClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Invoice Download Clicked', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {}
export const paymentFailure = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Payment Cancelled', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { code, utm_params }
export const couponCodeAppliedSuccess = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Coupon Code Success', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { code, utm_params }
export const couponCodeAppliedFailed = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Coupon Code Failed', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { }
export const editItemSegement = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Edit Item', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { }
export const removeItemSegment = ( data ) => {
    console.log("item data",data)
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Remove Item', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { }
export const removeCouponCode = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Remove Coupon Code', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

export const voiceOtpLink = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Voice OTP Link', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { }
export const TermsOfServiceLink = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Terms Of Service Link', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { }
export const proceedToPaymentSegment = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Proceed To Payment', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { }
export const payAfterService = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Pay After Service', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { status }
export const PayAfterServiceAgree = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Pay After Service - Agree', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { status }
export const PayAfterServiceDisagree = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Pay After Service - Disagree', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}