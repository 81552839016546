import {pushToDatalayer} from "../utils/tracking/gtmTracking";

const genericItems = (data) =>{
  return {
    item_id: `SKU_${data.sku}`,
    item_name: data.title || data.display_title,
    affiliation:'website',
    item_brand: data.brand || "NA",
    item_category: data.category,
    item_category2: data.category_type,
    item_category3: data.group,
    item_category4: data.product_type,
  }
}
export const viewItem = (data, list?:string, position?:number) => {
  try{
    let items:any =[{
      ...genericItems(data),
      item_list_name: list || '',
      price: Number(data.price),
      duration: data.duration,
      index: position,
    }]
    const payload = {
      ecommerce:{
       currency: "INR",
       items: items
      },
      category: 'E-commerce'
    }
    pushToDatalayer("clear_ecommerce", {ecommerce:null, category: 'E-commerce'})
    pushToDatalayer("view_item", {...payload})
  } catch {
    console.log('error while pushing to datalayer')
  }
}

export const selectItem = (data) => {
  try{
    let items:any =[{
      ...genericItems(data),
      price: Number(data.price) || Number(data.unit_price),
      duration: data.duration,
      index: data.index,
    }]
    const payload = {
      ecommerce:{
       currency: "INR",
       items: items
      },
      category: 'E-commerce'
    }
    pushToDatalayer("clear_ecommerce", {ecommerce:null, category: 'E-commerce'})
    pushToDatalayer("select_item", {...payload})
  } catch {
    console.log('error while pushing to datalayer')
  }
}

export const viewItemList = (data,plan="", list?:string) => {
  try {
    let items:any = []
    data && data.forEach((value, index) => {
      items.push({
        ...genericItems(value),
        item_list_name: list || '',
        price: value.price,
        duration: value.duration,
        plan: plan
      })
    })
    const payload = {
      ecommerce:{
       currency: "INR",
       items:items
      },
      category: 'E-commerce'
    }
      pushToDatalayer("clear_ecommerce", {ecommerce:null, category: 'E-commerce'})
      pushToDatalayer("view_item_list",{...payload})
  } catch(e:any) {
    console.log('error while pushing to datalayer: ', e)
  }
}

export const addToCartEvent = (data) =>{
  try {
      let items: any = [{
        ...genericItems(data),
        price: Number(data.unit_price),
        duration: data.duration,
        quantity: 1,
      }]
    const payload = {
      ecommerce:{
       currency: "INR",
       items:items
      },
      category: 'E-commerce'
    }
    pushToDatalayer("clear_ecommerce", {ecommerce:null, category: 'E-commerce'})
    pushToDatalayer("add_to_cart", {... payload})
  } catch(e) {
    console.log('error while pushing to datalayer', e)
  }
}

export const productCheckout = (data, gateway) => {
  try {
    let items:any = []
    data.items && data.items.forEach((value, index) =>{
      items.push({
        ...genericItems(value),
        price: Number(value.unit_price),
        duration: value.duration,
        quantity: value.quantity,
        coupon: value.discount_code?value.discount_code:'no coupon',
      })
    })
    const payload = {
      ecommerce:{
        currency: "INR",
        value: Number(data.items.reduce((sum, i) => {return sum + (Number(i.unit_price))}, 0)),
        items: items,
        discount_amount: parseFloat(data.discount_amount),
        gateway: gateway,
      },
      category: 'E-commerce'
    }
    pushToDatalayer("clear_ecommerce", {ecommerce:null, category: 'E-commerce'})
    pushToDatalayer("begin_checkout",{...payload})
  } catch {
    console.log('error while pushing to datalayer')
  }
}

export const productPayment = (data, order_id, discount_code) => {
  try {
    let items:any = []
    data.items && data.items.forEach((value, index) =>{
      items.push({
        ...genericItems(value),
        price: Number(value.unit_price),
        amount: Number(value.total_price),
        duration: value.duration,
        coupon: value.discount_code?value.discount_code:'no coupon',
        quantity: value.quantity,
      })
    })
    const payload = {
      ecommerce:{
        currency: "INR",
        coupon: discount_code,
        transaction_id: order_id,
        value: Number(data.items.reduce((sum, i) => {return sum + Number(i.unit_price)}, 0)),
        amount: data.amount,
        items: items,
        discount_amount: parseFloat(data.discount_amount)
      },
      category: 'E-commerce'
    }
    pushToDatalayer("clear_ecommerce", {ecommerce:null, category: 'E-commerce'})
    pushToDatalayer("purchase",{...payload})
  } catch {
    console.log('error while pushing to datalayer')
  }
}

export const viewCart = (data, amount) => {
  try {
    let items:any = []
    data && data.forEach((value, index) => {
      items.push({
        ...genericItems(value),
        price: value.unit_price,
        duration: value.duration,
        quantity: value.quantity,
      })
    })
    const payload = {
      ecommerce:{
       currency: "INR",
       value: amount,
       items: items
      },
      category: 'E-commerce'
    }
      pushToDatalayer("clear_ecommerce", {ecommerce:null, category: 'E-commerce'})
      pushToDatalayer("view_cart",{...payload})
  } catch(e:any) {
    console.log('error while pushing to datalayer: ', e)
  }
}

export const removeFromCart = (data) =>{
  try {
      let items: any = [{
        ...genericItems(data),
        price: Number(data.unit_price),
        duration: data.duration,
        quantity: data.quantity,
      }]
      const payload = {
        ecommerce:{
         currency: "INR",
         items: items
        },  
        category: 'E-commerce'
      }
    pushToDatalayer("clear_ecommerce", {ecommerce:null, category: 'E-commerce'})
    pushToDatalayer("remove_from_cart", {...payload})
  } catch(e) {
    console.log('error while pushing to datalayer', e)
  }
}

export const addShippingInfo = (addressString, amount , id) =>{
  try {
      let items: any = [{
        item_id: id,
        item_name: "cart address info",
        affiliation:'website',
      }]
      const payload = {
        ecommerce:{
         currency: "INR",
         value: amount,
         items: items,
         location: addressString
        },  
        category: 'E-commerce'
      }
    pushToDatalayer("clear_ecommerce", {ecommerce:null, category: 'E-commerce'})
    pushToDatalayer("add_shipping_info", {...payload})
  } catch(e) {
    console.log('error while pushing to datalayer', e)
  }
}

export const discountAppliedSuccess = (data, id) =>{
  try {
      let items: any = [{
        item_id: id,
        item_name: "discount applied successfully",
        affiliation:'website',
        coupon: data.discount_code,
      }]
      const payload = {
        ecommerce:{
        currency: "INR",
        value: data.amount,
        items: items,
        discount_amount: data.discount_amount
        },  
        category: 'E-commerce'
      }
    pushToDatalayer("clear_ecommerce", {ecommerce:null, category: 'E-commerce'})
    pushToDatalayer("select_promotion", {...payload})
  } catch(e) {
    console.log('error while pushing to datalayer', e)
  }
}

export const otpVerifiedOnCart = (cartData) =>{
  try {
      let cart_items:any = []
      cartData && cartData.forEach((value, index) => {
        cart_items.push(JSON.stringify({
          "name": value.display_title,
        }))
      })
      const payload = {
         ecommerce: {
          cart_items: cart_items
         },
         category: 'E-commerce'
        }
    pushToDatalayer("clear_ecommerce", {ecommerce:null, category: 'E-commerce'})
    pushToDatalayer("otp_verified_on_cart", {...payload})
  } catch(e) {
    console.log('error while pushing to datalayer', e)
  }
}