import * as planActionTypes from '../actionTypes/planActionTypes';

export const setCrossSellPlans = (data:any) => {
  return dispatch => {
    dispatch({
      type:planActionTypes.SET_CROSS_SELL_PLANS,
      data: data,
    })
  }
}

export const setCrossSellPlansInsta = (data:any) => {
  return dispatch => {
    dispatch({
      type:planActionTypes.SET_CROSS_SELL_PLANS_INSTA,
      data: data,
    })
  }
}

export const toggleSpecificFeatures = (data:string | undefined) => {
  return dispatch => {
    dispatch({
      type:planActionTypes.TOGGLE_SPECIFIC_FEATURES,
      data:data,
    })
  }
}

export const setHeaderData = (data:any) => {
  return dispatch => {
    dispatch({
      type:planActionTypes.SET_HEADER_DATA,
      data:data,
    })
  }
}

export const setCurrentStep = (data:any) => {
  return dispatch => {
    dispatch({
      type:planActionTypes.SET_CURRENT_STEP,
      data:data,
    })
  }
}

export const setAllPlans = (data:any) => {
  return dispatch => {
    dispatch({
      type:planActionTypes.SET_ALL_PLANS,
      data:data,
    })
  }
}

export const toggleHeaderSelection = (data:any) => {
  return dispatch => {
    dispatch({
      type:planActionTypes.TOGGLE_HEADER_SELECTION,
      data:data,
    })
  }
}

export const setScrollElementData = (data:any) => {
  return dispatch => {
    dispatch({
      type:planActionTypes.SET_SCROLL_DATA,
      data:data
    })
  }
}
