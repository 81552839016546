export const FETCH = "FETCH"

export const AUTHENTICATED = "AUTHENTICATED"

export const CHANGE_TAB = "CHANGE_TAB"

export const STORE_TOKEN = "STORE_TOKEN"

export const UPDATE_DEVICE = "UPDATE_DEVICE"

export const UPDATE_DEVICES = "UPDATE_DEVICES"

export const UPDATE_PLAN_ERRORS = "UPDATE_PLAN_ERRORS"

export const ACCORDIAN_CHANGE = "ACCORDIAN_CHANGE"

export const CUSTOMER_INFO_PAYLOAD = "CUSTOMER_INFO_PAYLOAD"

export const UPDATE_DEVICE_ERRORS = "UPDATE_DEVICE_ERRORS"

export const UPDATE_PERSONALDETAILS = "UPDATE_PERSONALDETAILS"

export const UPDATE_PERSONALDETAILS_ERRORS = "UPDATE_PERSONALDETAILS_ERRORS"

export const RESET_PERSONALDETAILS_ERRORS = "RESET_PERSONALDETAILS_ERRORS"

export const RESET_DEVICEDETAILS_ERRORS = "RESET_DEVICEDETAILS_ERRORS"

export const RESET_PLANDETAILS_ERRORS = "RESET_PLANDETAILS_ERRORS"

export const SHOW_ACTIVATION_ERROR_POPUP = "SHOW_ACTIVATION_ERROR_POPUP"

export const DISMISS_ACTIVATION_ERROR = "DISMISS_ACTIVATION_ERROR"

export const UPDATE_UPSELL_PLAN = "UPDATE_UPSELL_PLAN"

export const CLEAR_ACTIVATION = "CLEAR_ACTIVATION"

export const BTN_LOADING = "BTN_LOADING"

export const ADDED_ADDRESS = "ADDED_ADDRESS"

export const ACTIVATION_OSID = 'ACTIVATION_OSID'

export const TOOL_TIP = 'TOOL_TIP'

export const DEVICE_FIELDS = 'DEVICE_FIELDS'

export const FIELD_NAME = 'FIELD_NAME'

export const IMAGE_VIEW = 'IMAGE_VIEW'

export const IS_CERTIFICATE = 'IS_CERTIFICATE'

export const UNAUTHORIZED_CUSTOMER = 'UNAUTHORIZED_CUSTOMER'