import { PushToTracker  } from "../../utils/tracking";

// data contains - {page_link}
export const logoClick = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Logo Click', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {page_link, config, utm_params , target_url}
export const navItemClick = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Nav Item Click', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {page_link, config, utm_params , target_url}
export const signinClick = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Sign in Clicked', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {page_link, config, utm_params , target_url}
export const trackServiceRequestScreenViewed = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Track Service Request Screen Viewed', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {page_link, config, utm_params , target_url}
export const homeActivatePlanTapped = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Home - Activate Plan Tapped', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {page_link, config, utm_params , target_url}
export const homeServiceRequestTapped = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Home - Service Request Tapped', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - {page_link, cart_items, cart_amount}
export const cartWidgetClick = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Cart Widget Click', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}