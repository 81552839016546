import React, { useEffect, useState } from "react"
import { connect } from 'react-redux'
import { setScrollElementData } from '../../../redux/actions/planActions'
import styles from '../../../styles/modules/components/chat-component.module.scss'
import Image from '../Image';
import Hidden from '@mui/material/Hidden'
import { chatbotClicked } from "../../../tracking/segment/footer";
import { getPageURL } from "../../../utils/tracking";

const ChatComponent = (props:any) => {

    const [moveChatBotUp, setMoveChatBotUP ] = useState(false)
    const [isMobile, setIsMobile] = useState(false)

    useEffect(()=> {
        if (!!props.ScrollTillElementID){
            props.setScrollElementData(props.ScrollTillElementID)
        }
        window.innerWidth > 992 ? setIsMobile(false) : setIsMobile(true);
    }, [])

    useEffect(()=>{
        const isBottomSticky = document.getElementById('bottom-sticky-header')
        const selfServeSticky = document.getElementById('selfserve-bottom-sticky')
        const trackerBottomSticky = document.getElementById('tracker-bottom-sticky')
        if(props.cart.items.length > 0 && isBottomSticky) {
            setMoveChatBotUP(true)
        } else if (selfServeSticky) {
            setMoveChatBotUP(true)
        } else if(isMobile && trackerBottomSticky){
            setMoveChatBotUP(true)
        }else {
            setMoveChatBotUP(false)
        }
    },[props.cart.items, isMobile, props.selfserveReducer.current_step])

    const scrollToTop = (event) => {
        event.preventDefault()
        const c_scroll = window.pageYOffset;
        if(c_scroll >= 1) {
            const element = document.getElementById("plan-selection")
            if(element) {
                let offsetPosition  = element.getBoundingClientRect().top + window.pageYOffset;
                window.scrollTo({
                    top: offsetPosition,
                    behavior: "smooth"
                  });
            } else {
                window.scroll({
                    behavior: 'smooth',
                    left:0,
                    top: 0
                });
            }
        }
    }
    return (
        !isMobile ? <React.Fragment>
           <a href='https://onst.in/4dCuJA' onClick={()=>{chatbotClicked({...getPageURL()})}} className={moveChatBotUp ? `${styles.chat__component} ${styles.chat_button} ${styles.add_item__chaticon} whatsapp_chatbottt`:`${styles.chat__component} ${styles.chat_button}`} target="_blank" rel="noopener noreferrer">
                <div className={styles._img}>
                {isMobile ?
                    <Image src={"/static/images/whatsapp_icon.webp" || ''} alt="Chat icon" width="56" height="56" layout="responsive"/>
                    :
                    <Image src={"/static/images/whatsapp_icon.webp" || ''} alt="Chat icon" width="64" height="64" layout="responsive"/>
                }
                </div>
                <span>Chat with Experts</span>
            </a>
            <Hidden smDown>
            {props.cart.showScrollTop && (<a href='#' className={`${styles.chat__component} ${styles.scroll_button}`} onClick={scrollToTop} >
            {props.generic.showHaptik && <Image src={"/static/images/back-to-top.svg" || ''} alt="scrollToTop icon" width="730" height="516" />}
            </a>)}
            </Hidden>
        </React.Fragment>: <></>
    );
}

const mapStateToProps = (state) => {
    return {
        cart: state.cart,
        generic: state.genericReducer,
        selfserveReducer: state.selfserveReducer
    }
}

const mapDispatchToProps =(dispatch: any) => {
    return {
        setScrollElementData: (data: any) => dispatch(setScrollElementData(data)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps) (ChatComponent);
