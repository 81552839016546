import { PushToTracker } from "../../utils/tracking"

export const cleverTapTrigger = (eventname:string, data:any)=>{
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":eventname, "payload":{...data}}})
                
    }catch{
        console.log(`error while pushing to datalayer ${eventname}`)
    }
}
