import * as activationActionTypes from '../actionTypes/activationActionTypes';
import {getExtraDeviceData} from '../../utils/activation/registration';
interface State {
  id?: string,
  devices?: {
    id: number,
    plans:{
      id: number,
      osid: string,
      plan_purchased_date: string,
      product_type: string,
      plan_purchased_from: string,
      errors: object
    }[],
    device_price:number,
    category_type:string,
    expandDeviceDetails: true,
    expandDeviceUpload: true,
    expandScheduleDetails: true,
    expandUpsellDetails: true,
    errors: object,
    showCongrats: any
  }[],
  name?:string,
  phone?:string,
  email?:string,
  address?:string,
  pincode?:number|null,
  tab_id: number|null,
  is_authenticated: boolean|null,
  token?: string,
  expandPersonalDetails: boolean,
  canEditPersonalDetail: boolean,
  customerInfoPayload: object,
  errors: object,
  activationError?: object,
  loaderbtn?: any,
  addedAddress?: any,
  activationOsid?: any,
  tooltip?: any,
  dropdownFields?: {
    brand?: any,
    device_name?: any,
  },
  fieldName?: any,
  deviceDetails?: {
    model?: string,
    serial?: string,
    device_price: any,
    brand?: string,
    device_purchased_date?: any,
    manufacturer_warranty?: any,
    device_condition?: any,
    devices?:any,
    isImageView?: boolean,
    files?: any,
  }
  isCertificate?: boolean,
  unauthorized_customer?: boolean,
}

const initialState:State = {
  devices: [],
  tab_id:null,
  is_authenticated: null,
  expandPersonalDetails: true,
  canEditPersonalDetail: true,
  customerInfoPayload: {},
  errors: {},
  loaderbtn: "",
  addedAddress: {},
  fieldName: "None",
  dropdownFields: {"brand": [], "device_name": []},
  deviceDetails: {
    model: "",
    serial: "",
    device_price: "",
    brand: "",
    device_purchased_date: "",
    manufacturer_warranty: "",
    device_condition: "",
    devices:"",
  },
  isCertificate: false,
  unauthorized_customer : false
}

export const getOrder = (order) => {
  let data = {...order}
  const {name, email, phone, pincode} = order;
  if(!!name && !!email && !!phone && !!pincode){
    data['expandPersonalDetails'] = false
  }
  for(let device of order.devices){
    for(let plan of device.plans){
      if(['2', '5', '7', '6', '4', '3'].indexOf(plan.status.toString())!=-1){
        data['canEditPersonalDetail'] = false
        break
      }
    }
  }
  return data
}

export default function actionReducer(state=initialState, action:any) {
  switch (action.type) {
    case activationActionTypes.FETCH:
      return {
        ...state,
        ...getOrder(action.data),
        devices: action.data.devices.map((device) => {
          return {errors: {}, ...device, ...getExtraDeviceData(device)}
        })
      }
    case activationActionTypes.UPDATE_DEVICE:
      let canEditPersonalDetail = state.canEditPersonalDetail
      for(let plan of action.device.plans){
        if(['2', '5', '7', '6', '4', '3'].indexOf(plan.status.toString())!=-1){
          canEditPersonalDetail = false
          break
        }
      }
      let foundErrors = action.device.plans.filter(item => ["3"].indexOf(item.status.toString())!=-1)
      let foundCerts = action.device.plans.filter(item => ["2", "5", "7"].indexOf(item.status.toString())!=-1)
      if(foundCerts.length && !foundErrors.length){
        action.device['showCongrats'] = true
      }
      return {
        ...state,
        canEditPersonalDetail: canEditPersonalDetail,
        devices: state.devices?.map((device)=>{
          if(device.id!=action.device.id){
            return device
          }else{
            return {errors: {}, ...action.device, ...getExtraDeviceData(action.device)}
          }
        })
      }

    case activationActionTypes.CLEAR_ACTIVATION:
      return {
        ...initialState
      }

    case activationActionTypes.UPDATE_DEVICES:
      return {
        ...state,
        devices: state.devices?.map((device)=>{
          let newDevice = action.devices.filter(_action_device => _action_device.id==device.id)
          if(newDevice.length){
            if(newDevice[0].id==device.id){
              return {...device, ...newDevice[0]}
            }
          }
          return device
        })
      }

    case activationActionTypes.UPDATE_PERSONALDETAILS:
      return {
        ...state,
        ...action.data
      }
      
    case activationActionTypes.CUSTOMER_INFO_PAYLOAD:
      return {
        ...state,
        customerInfoPayload: action.data
      }
      
    case activationActionTypes.AUTHENTICATED:
      return {...state, is_authenticated: action.is_authenticated}
    case activationActionTypes.CHANGE_TAB:
      return {...state, tab_id: action.tab_id}

    case activationActionTypes.STORE_TOKEN:
      return {...state, token: action.token}
    
    case activationActionTypes.ACCORDIAN_CHANGE:
      switch(action.accordian){
        case 'expandPersonalDetails':
          return {...state, expandPersonalDetails: action.value}
        case 'expandDeviceDetails':
          return {...state, devices: state.devices?.map(item => action.tab_id==item.id? {...item, expandDeviceDetails: action.value} : item)}
        case 'expandDeviceUpload':
          return {...state, devices: state.devices?.map(item => action.tab_id==item.id? {...item, expandDeviceUpload: action.value} : item)}
        case 'expandUpsellDetails':
          return {...state, devices: state.devices?.map(item => action.tab_id==item.id? {...item, expandUpsellDetails: action.value} : item)}
        default: return state
      }
    
    case activationActionTypes.UPDATE_DEVICE_ERRORS:
      return {...state, errors: action.errors}
    
    case activationActionTypes.UPDATE_PLAN_ERRORS:
      return {
        ...state, 
        devices: state.devices?.map((device) => {
          if(action.device_id==device.id){
            return {
              ...device,
              plans: device.plans?.map(plan => {
                if(plan.id==action.plan_id){
                  let errors = plan.errors || {};
                  return {...plan, errors: {...errors, ...action.errors}}
                }
                return plan
              })
            }
          }
          return device
        })
      }
      
    case activationActionTypes.UPDATE_PERSONALDETAILS_ERRORS:
      return {...state, errors: action.errors}

    case activationActionTypes.RESET_PERSONALDETAILS_ERRORS:
      return {...state, errors: {...state.errors, [action.field]: undefined}}
    
    case activationActionTypes.RESET_DEVICEDETAILS_ERRORS:
      return {...state, errors: {...state.errors, [action.field]: undefined}}
    
    case activationActionTypes.RESET_PLANDETAILS_ERRORS:
      return {
        ...state, 
        devices: state.devices?.map((device) => {
          if(action.device_id==device.id){
            return {
              ...device, 
              plans: device.plans?.map(plan => {
                if(plan.id==action.plan_id){
                  return {...plan, errors: {...plan.errors, [action.field]: undefined}}
                }
                return plan
              })
            }
          }
          return device
        })
      }
    case activationActionTypes.SHOW_ACTIVATION_ERROR_POPUP:
      return {
        ...state,
        devices: state.devices?.map((device) => {
          if(action.device_id==device.id){
            return {
              ...device, 
              activationError: {
                errors: action.errors,
                device_id: action.device_id,
                payload: action.payload
              }
            }
          }
          return device
        })
      }

    case activationActionTypes.DISMISS_ACTIVATION_ERROR:
      return {
        ...state,
        devices: state.devices?.map((device) => {
          if(action.device_id==device.id){
            return {
              ...device, 
              activationError: undefined,
              expandDeviceDetails: true
            }
          }
          return device
        })
      }
    case activationActionTypes.UPDATE_UPSELL_PLAN:
      return {
        ...state,
        devices: state.devices?.map((device) => {
          if(action.device_id==device.id){
            return {
              ...device,
              expandUpsellDetails: true,
              plans: device.plans?.map((plan)=> {
                if(action.plan_id==plan.id){
                  return {
                    ...plan,
                    upsell: action.upsell_plan
                  }
                }   
                return plan
              })
            }
          }
          return device
        })
      }
    
    case activationActionTypes.BTN_LOADING:
      return {...state, loaderbtn: action.button_name}
      
    case activationActionTypes.ADDED_ADDRESS:
      return {
        ...state,
        addedAddress: action.data
      }

    case activationActionTypes.ACTIVATION_OSID:
      return {
        ...state,
        activationOsid: action.data
      }

    case activationActionTypes.TOOL_TIP:
      return {
        ...state,
        tooltip: action.data
      }

    case activationActionTypes.DEVICE_FIELDS:
      let data = action.data
      return {
        ...state,
        dropdownFields: {...state.dropdownFields, ...data}
      }

    case activationActionTypes.FIELD_NAME:
      return {
        ...state,
        fieldName: action.data
      }
  
    case activationActionTypes.IMAGE_VIEW:
      return {
        ...state,
        isImageView: action.isOpen,
        files: action.files
      }

    case activationActionTypes.IS_CERTIFICATE:
      return {
        ...state,
        isCertificate: action.data
      }
    case activationActionTypes.UNAUTHORIZED_CUSTOMER:
      return {
        ...state,
        unauthorized_customer: action.data
      }
      
      
    default:
      return state
  }
}