import { PushToTracker  } from "../../utils/tracking";

// data contains - { link, page }
export const linkClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Link Clicked', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { page_link }
export const chatbotClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Chatbot Clicked', "payload":{...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { refer_link }
export const referNow = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Refer Now', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { social_media_link, name }
export const socialMediaIconClick = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Social Media Icon Click', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}