import * as profileActionTypes from '../actionTypes/profileActionTypes';

export const updateProfileData = (data:any)=> {
    return dispatch =>{
        dispatch({
            type:profileActionTypes.LOGIN_USER,
            data: data
        })
    }
}
