import {AMC_PLANS, SERIAL_NUMBER_CATEGORY, SERIAL_NUMBER_CATEGORY_TYPES} from '../../constants/activation';
import Cookies from 'js-cookie'

export const getDeviceName = (device:any) => {
  if(device.brand && !(device.category.includes(device.brand))){
    return `${device.brand} ${device.category}`
  }
  return device.category
}

export const isAMC = (_device, plan) => {
  return AMC_PLANS.indexOf(plan.product_type)!=-1
}

export const alterTitle = (title, channel) => {
  const titleMapping={
    "iProtect": "iPROTECT",
    "iProtect Gold": "iPROTECT GOLD",
  }
  title = titleMapping?.[title] || title
  const alterations:any = {
    'vs': (title) => `VS+ ${title}`,
    'vs-tele': (title) => `VS+ ${title}`
  }
  if(channel && channel !== '') {
    if(alterations[channel]) {
      return alterations[channel](title)
    }
  }
  if(channel === 'lotus-electronics' && ['Mobile Total Care','Spills and Drops / Damage Protection'].includes(title)){
    title = `Lotus Care+ ${title}`
  }

  return title
}

export const getActivationSource = (router) => {
  if('activation_source' in router.query){
    if(router.query.activation_source=='offline_activation'){
      return 'Offline Activation'
    }else if(router.query.activation_source=='my_plans'){
      return 'My Plans'
    }
  }
  return Cookies.get('activation_source');
}

export const setActivationSource = (value) => {
  Cookies.set('activation_source', value)
}

export const getIsSerialNumberRequired = (device) => {
  return SERIAL_NUMBER_CATEGORY.some((i) => i === device?.category?.toLowerCase()) || SERIAL_NUMBER_CATEGORY_TYPES.some((i) => i === device?.category_type?.toLowerCase())
}