import Moment from 'moment';

export const getCartSummary = (items) => {
    let data = {
        'quantity': 0,
        'amount': 0,
        'discount_amount': 0,
        'item_total': 0
    }
    items.forEach(item => {
        data['quantity'] += parseInt(item.quantity)
        data['amount'] += parseInt(item.total_price)
        data['discount_amount'] += parseInt(item.discount_amount)
        data['item_total'] += (parseInt(item.unit_price)*item.quantity)
    });
    return data
}

export const getParameterByName = (name, url = window.location.href) => {
  name = name.replace(/[\[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
      results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}


export const getCartItemsSkuId = (items) => {
  let data:any = {'category':'','sku_name':'','product_type':''}
  for(let item of items){
    data.category = data.category + item.category + ',',
    data.sku_name = data.sku_name + item.product_title + ',',
    data.product_type = data.product_type + item.product_type + ','
  }
  return data;
}

export const isServiceable = (items:any) => {
  // ! Don't Changes this Code. It's temporary fix for serviceable pincode
  // Todo - We Need Revamp Personal Details Component
  return Object.keys(items).filter(item=> items[item]==false).length

} 

// conver date yyyy-mm-dd to month-year
export const convertDateForTestinmonial = (date:any) => {
    var moment:any = Moment(date);
    return moment.format('MMM YYYY')
}

// Conver to Capitalize
export const CapitalizeString = (string:any) => {
  string = string.toLowerCase().split(' ')
  const new_string = string.map((str) => {
    return str.charAt(0).toUpperCase() + str.slice(1)
  })
  return new_string.join(' ')
}
export const isAmcInstaCart = (cart:any) => {
    if(cart.type == 'amc' || cart.type == 'insta-repair'){
      return true
    }
    return false
  }
