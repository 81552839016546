import * as trackerActionTypes from '../actionTypes/trackerActionTypes';

interface State {
  'search': any,
  'srDetail': any,
  'addressDetails': any,
  'loader': boolean,
  'currentStep': any,
  'resetAddress': any,
  'APIlatLng': any,
  'addressComponent': any,
  'errors': any,
  'tempLatLng': any,
  'currLatLng': any,
  'isMapFullScreen': boolean,
  'CTSource': any,
}

const initialState:State = {
  'search': {
    'inProgress': false,
    'value': null,
    'results': [],
    'count': 0,
    'page': 1,
    'next': null,
    'previous': null,
    'valid': false,
  },
  'srDetail': {
      'data': {
        'tracker_milestones': [],
        'device_details': {},
        'issues': [],
      },
      'inProgress': false,
  },
  addressDetails: {},
  loader: true,
  currentStep: 1,
  resetAddress: {},
  APIlatLng: {},
  addressComponent: {},
  errors: {},
  tempLatLng: {},
  currLatLng: {},
  isMapFullScreen: false,
  CTSource: 'Track SR Link'
}

export default function trackerReducer(state=initialState, action:any) {
  switch (action.type) {
    case trackerActionTypes.BEGIN_SEARCH:
      return {
          ...state,
          search: {
            ...state.search,
            inProgress: true,
          }
      }
    case trackerActionTypes.SEARCH_SERVICE_REQUESTS:
      let { results } = state.search;
      if (action.page > 1) {
          results = results.concat(action.results);
      } else {
          results = action.results;
      }
      return {
          ...state,
          search: {
            inProgress: false,
            value: action.value,
            results: results,
            count: action.count,
            next: action.next,
            previous: action.previous,
            page: action.page,
            valid: action.valid,
          }
      }
    case trackerActionTypes.BEGIN_LOAD_SR_DETAIL:
      return {
        ...state,
        srDetail: {
          ...state.srDetail,
            inProgress: true
        }
      }
    case trackerActionTypes.LOAD_SERVICE_REQUEST_DETAIL:
      return {
        ...state,
        srDetail: {
          ...state.srDetail,
            inProgress: false,
            data: {
              ...initialState.srDetail.data,
              ...action.data,
            }
        }
      }
    case trackerActionTypes.SR_ADDRESS_DETAILS:
      return {
          ...state,
          addressDetails: action.data
      }  
    case trackerActionTypes.BEGIN_LOADER:
      return {
          ...state,
          loader: action.data
      }
    case trackerActionTypes.CURRENT_STEP:
      return {
          ...state,
          currentStep: action.data
      }
    case trackerActionTypes.RESET_ADDRESS:
      return {
          ...state,
          resetAddress: action.data
      }
    case trackerActionTypes.API_LAT_LNG:
      return {
          ...state,
          APIlatLng: action.data
      }
    case trackerActionTypes.ADDRESS_COMPONENT:
      return {
          ...state,
          addressComponent: {...state.addressComponent, ...action.data}
      }  
    case trackerActionTypes.ERRORS:
    return {
        ...state,
        errors: {...state.errors, ...action.data}
    }
    case trackerActionTypes.TEMP_LAT_LNG:
      return {
          ...state,
          tempLatLng: action.data
      }
    case trackerActionTypes.CURR_LAT_LNG:
      return {
          ...state,
          currLatLng: action.data
      }
    case trackerActionTypes.MAP_FULL_SCREEN:
      return {
          ...state,
          isMapFullScreen: action.data
      }
    case trackerActionTypes.CT_SOURCE:
      return {
          ...state,
          CTSource: action.data
      }              
    default:
      return state
  }
}
