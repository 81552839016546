import * as genericActionTypes from '../actionTypes/genericActionTypes';

interface State {
  message: Object | null,
  canOptInWhatsapp: boolean,
  userCity: string,
  showBanner: boolean,
  pineLabsCampaign: boolean
  withoutNavBar:boolean
  cityChangePopup: boolean,
  city_data: any,
  consumerDisableSkuConfigIr: any,
  consumerDisableSkuConfigAmc: any,
  toastMessage: any
}

const initialState:State = {
  'message': null,
  'canOptInWhatsapp': false,
  'userCity': '',
  'showBanner': true,
  'withoutNavBar':false,
  'cityChangePopup': false,
  'city_data': {},
  'pineLabsCampaign': false,
  'consumerDisableSkuConfigIr': {},
  'consumerDisableSkuConfigAmc': {},
  'toastMessage':null
}

export default function genericReducer(state=initialState, action:any) {
  switch (action.type) {
    case genericActionTypes.SHOW_QUICKALERT:
      return {
        ...state,
        message: action.message
      }
    case genericActionTypes.HIDE_QUICKALERT:
      return {
        ...state,
        message: null
      }
    case genericActionTypes.TOGGLE_HAPTIK_ICON:
        return {
          ...state,
          showHaptik:action.data,
        }
    case genericActionTypes.SET_WHATSAPP_AVAILABILITY:
      return {
        ...state,
        canOptInWhatsapp: action.data
      }
    case genericActionTypes.SET_USER_CITY:
      return {
        ...state,
        userCity: action.data
      }
    case genericActionTypes.SET_SHOW_BANNER:
      return {
        ...state,
        showBanner: action.data
      }
    case genericActionTypes.HIDE_NAVIGATION_BAR:
        return {
          ...state,
          withoutNavBar:action.data
        }  
      case genericActionTypes.SET_PINE_LABS_CAMPAIGN:
        return {
          ...state,
          pineLabsCampaign: action.data
        }
    case genericActionTypes.SET_CITY_CHANGE_POPUP:
      return {
        ...state,
        cityChangePopup: action.data.state,
        city_data: action.data.city_data
      }
    case genericActionTypes.SET_DISPLAY_SKU_CONFIG:
        return {
          ...state,
          consumerDisableSkuConfigIr: action.data['CONSUMER_DISABLE_SKU_CONFIG_IR'],
          consumerDisableSkuConfigAmc: action.data['CONSUMER_DISABLE_SKU_CONFIG_AMC']
        }
    case genericActionTypes.SET_TOAST:
      return {
        ...state,
        toastMessage: action.data
      }
    default:
      return state
  }
}