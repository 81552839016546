import React from 'react';
import { isMobile } from 'react-device-detect';
import Link from 'next/link';
import Image from '../Image';
import { toggleHeaderSelection } from '../../../redux/actions/planActions';
import { connect } from 'react-redux';
import { toggleWidget, toggleScrollTop, toggleCartAlert, showToast } from '../../../redux/actions/cartActions';
import styles from '../../../styles/modules/common/header.module.scss';
import { combineParamsString, getUtmString, getStaticPath, getUtmParams } from "../../../utils";
import { setSignOutAlert } from "../../../redux/actions/customerActions";
import { AppBanner } from '@/components/home/AppBanner';
import { setShowBanner } from '../../../redux/actions/genericActions';
import { cartWidgetClick, logoClick } from '../../../tracking/segment/header';
import { withRouter } from 'next/router';
import { getCartItemsSkuId } from '../../../utils/cart';
import { chatbotClicked } from '../../../tracking/segment/footer';
import { getPageURL } from '../../../utils/tracking';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { blockSalesTouchPoint } from '../../../utils/myaccount';
import Toast from '@/components/UI/GenericToast'
import dynamic from 'next/dynamic';
import { cleverTapTrigger } from '../../../tracking/segment';

const VisibilityManager = dynamic(()=> import("../../../components/HOC/visibilityManager"),
  {loading: () => <></>,ssr:true }
);
const Navigation = dynamic(()=> import("./Navigation"),
  {loading: () => <></>,ssr:true }
);
const UIPopover = dynamic(()=> import("../ui-components/surface/UIPopover"),
  {loading: () => <></>,ssr:true }
);
const SignOutAlert = dynamic(()=> import("./signOutAlert"),
  {loading: () => <></>,ssr:true }
);
const UIModal = dynamic(()=> import("../../common/ui-components/surface/UIModal"),
  {loading: () => <></>,ssr:true }
);

class HeaderJSX extends React.PureComponent<any> {
  state = {
    cartWidget: false,
    isNavOpen: false,
    isHeaderFixed: true,
    triggerOffset: true,
    showToast: false,
    isProfileOpen: false,
    blockSalesPoint: false,
  }
  setHeader = (b: boolean) => {
    this.setState({
      isHeaderFixed: b,
    });
  };
  toggleCartWidget = () => {
    this.setState({
      cartWidget: !this.state.cartWidget,
    });
  };
  toggleNav = () => {
    this.setState({
      isNavOpen: !this.state.isNavOpen,
      isProfileOpen: false,
    });
  };

  homeUrl = "/";

  showScrollTop = () => {
    if (!!this.props.plans.showScrollAfterElement) {
      const currentScrollPosition = window.pageYOffset;
      let threshHoldElement = document.getElementById(
        this.props.plans.showScrollAfterElement
      );
      if (
        !threshHoldElement &&
        this.props.productType == "extended-warranty-plans"
      ) {
        threshHoldElement = document.getElementById("two-row-features");
      }
      let elementOffsetTop = threshHoldElement
        ? threshHoldElement.offsetTop
        : 0;

      const screenFix =
        elementOffsetTop - window.innerHeight < 0
          ? elementOffsetTop
          : elementOffsetTop - window.innerHeight; // to handle case for long and short scroll
      if (
        elementOffsetTop !== 0 &&
        currentScrollPosition > 0 &&
        currentScrollPosition > screenFix
      ) {
        if (!this.props.cart.showScrollTop) {
          this.props.toggleScrollTop(true);
        }
      } else {
        if (this.props.cart.showScrollTop) {
          this.props.toggleScrollTop(false);
        }
      }
    }
  };

  setHeaderPosition = () => {
    let headerOffset = document.querySelector("header")
      ? document.querySelector("header")?.clientHeight
      : 0;
    if (headerOffset) {
      if (window.pageYOffset > headerOffset) {
        !this.state.isHeaderFixed ? this.setHeader(true) : null;
      } else {
        this.state.isHeaderFixed ? this.setHeader(false) : null;
      }
    }
    this.showScrollTop();
  }

  setAppBannerVisibility = () => {
    this.props.setShowBanner(false)
  }

  componentDidUpdate = () => {
    if (isMobile && this.props.withNav) {
      const root_el = document.documentElement;
      if (root_el) {

        if (this.state.isNavOpen || this.state.isProfileOpen) {
          root_el.style.overflowY = "hidden";
        }
        else {
          root_el.style.overflowY = "auto";
        }
      }
    }
    if (this.props.cart?.showToast) {
      this.setState({ showToast: true })
      setTimeout(() => {
        this.props.setShowToast(false)
        this.setState({ showToast: false })
      }, 5000)
      this.props.router.events.on('routeChangeComplete', () => {
        this.props.setShowToast(false)
        this.setState({ showToast: false })
      })
    }
    if (!this.props.showBanner) {
      document.body.classList.add('hide_banner_body');
    }
    window.addEventListener('scroll', () => {
      let windowTop = window.pageYOffset;
      if (windowTop > 0 && this.props.showBanner) {
        this.setState({ triggerOffset: false })
        document.body.classList.add('hide_banner_body');
      }
      else if (this.props.showBanner) {
        this.setState({ triggerOffset: true })
        document.body.classList.remove('hide_banner_body');
      }
    });
  }
  componentDidMount = () => {
    this.homeUrl = combineParamsString("/", getUtmString());

    if (window.location.href.indexOf("#") == -1) {
      window.scrollTo(0, 0);
    }
    let headerOffset = document.querySelector('header') ? document.querySelector('header')?.clientHeight : 0;


    let windowTop = window.pageYOffset
    if (headerOffset) {
      if (windowTop > headerOffset) {
        this.setHeader(true);
      }
    }
    window.addEventListener('scroll', this.setHeaderPosition, {
      capture: false,
      passive: true
    });
  }

  getCartQuantity = () => {
    if (this.props.cart.type == "homecare") {
      return this.props.cart.items?.filter((i: any) => i.parent_plan == null)
        .length;
    }
    return this.props.cart.quantity;
  };

  componentWillUnmount = () => {
    window.removeEventListener('scroll', this.setHeaderPosition);
  }

  logoClickAction = () => {
    logoClick({ page_link: this.props.router.asPath })
    if (this.state.isNavOpen) { this.toggleNav() }
  }
  toggleProfile = () => {
    this.setState({
      isProfileOpen: !this.state.isProfileOpen,
      isNavOpen: false,
    })
  }

  render() {
    const { className, withNav, IsCoBranded, IsInverse, CoBrandedLogo, toggleCart, withCart, withProfile, pineLabs, cart, plans, salesproPage, showPhoneNumber = true, TRAddress } = this.props;
    const disableRouting = (pineLabs || blockSalesTouchPoint(this.props.router))
    return (
      <React.Fragment>
        <header id="header" className={`${styles.header} ${this.state.isHeaderFixed ? styles.stickey : ''} ${className ? className : ''} hide_on_app_render ${this.props.withoutNavBar ? styles.hide : null}`.trim()} data-inverse={IsInverse}>
          {this.props.showBanner && <AppBanner router={this.props.router} setVisible={this.setAppBannerVisibility} />}
          <div className={`${styles._container} container`}>
            <div className={`${styles._row} row`}>
              <div className={`${styles._left} col-4 col-md-6 col-lg-2`}>
                <div>
                  {withNav && !blockSalesTouchPoint(this.props.router) && (
                    <div
                      className={`${styles.nav_trigger} ${this.state.isNavOpen ? styles.__open : ""
                        }`.trim()}
                    >
                      <Link
                        href="#"
                        aria-label="hamburger navigation"
                        onClick={(e) => {
                          e.preventDefault();
                          this.toggleNav();
                        }}></Link>
                    </div>
                  )}
                </div>
                <div className={`${styles._branding}`}>
                  <figure className={`${styles._logo}`}>
                    <Link
                      href={this.homeUrl}
                      className={disableRouting?styles.isDisabled:''}
                      onClick={() => { this.logoClickAction() }}
                      title="Onsitego Electro Services Pvt Ltd.">
                      Onsitego Electro Services Pvt Ltd.
                    </Link>
                  </figure>
                </div>

              </div>
              <div className={`${styles._right} col-8 col-md-6 col-lg-10`}>

                {!pineLabs && !salesproPage && this.props.showChatIcon && <span className={`${styles.cart_icon} ${styles.pulsate}`}>
                  <Link href='https://onst.in/4dCuJA'
                    onClick={() => { chatbotClicked({ ...getPageURL() }) }}
                    className={`${styles.whatsapp_btn} ${!withCart ? styles.whatsapp_nopadding : ""}`}
                    data-icon={this.state.cartWidget}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    whatsapp
                  </Link>
                </span>}
                {!blockSalesTouchPoint(this.props.router) && withCart && (
                  <span
                    className={styles.cart_icon}
                    onClick={(e: React.MouseEvent) => {
                      cartWidgetClick({ 'page_link': this.props.router.asPath, 'cart_amount': this.props.cart?.item_total, 'quantity': this.props.cart?.quantity, ...getCartItemsSkuId(this.props.cart?.items), ...getUtmParams() })   // Segment Events
                      e.preventDefault();
                      if (cart.type === undefined) {
                        cart.showCartWidget = true;
                      }
                      this.toggleCartWidget();
                    }}
                  >
                    <Link
                      className={styles.cart_btn}
                      data-icon={this.state.cartWidget}
                      href="#"
                    >
                      cart
                    </Link>
                    {cart.items && cart.items.length > 0 && (
                      <span className={styles.cart_count}>
                        {this.getCartQuantity()}
                      </span>
                    )}
                  </span>
                )}
                {withProfile &&
                <span className={`${styles.profile_icon}`} style={this.state.isProfileOpen?{opacity:"0.5"}:{}} data-inverse={IsInverse}>
                <AccountCircleIcon className={`${styles.profile_btn}`} onClick={(e) => {
                  e.preventDefault();
                  this.toggleProfile();}}/>
              </span>
                }
                {!pineLabs && withNav && (
                  <Navigation
                    isDisabled={this.state.cartWidget}
                    category_links={
                      this.props.category_links ? this.props.category_links : []
                    }
                    isOpen={this.state.isNavOpen}
                    toggleNav={this.toggleNav}
                    isInverse={IsInverse}
                    router={this.props.router}
                    toggleProfile={this.toggleProfile}
                    isProfileOpen={this.state.isProfileOpen}
                  />
                )}
                {this.props.children && this.props.children}
                {!withNav && IsCoBranded && (
                  <span className={styles.cobrand}>
                    <img   src={CoBrandedLogo || ""} alt={"co-branding"}/>
                  </span>
                )}
                {!withNav && !IsCoBranded && showPhoneNumber && !salesproPage && (
                  <span
                    className={`app-link app-link--light ${styles.help_link}`}
                  >
                    Need help? {TRAddress ? "Give us a call at 9920599206" : <a href='https://onst.in/4dCuJA' target="_blank" rel="noopener noreferrer"><i><Image src={getStaticPath(`/static/images/whatsapp_icon.png`)} width="20px" height="20px" alt={"whatsapp icon"}/></i>
                    Chat with Us</a>}
                  </span>
                )}
                {salesproPage && (
                  <span
                    className={`${styles.salespro_link}`}
                    onClick={()=>{cleverTapTrigger("Download Salespro Clicked", {})}}
                  >
                    <a href='https://salespro.app.link/nrkz4MkWgDb' target="_blank" rel="noopener noreferrer"><i><Image src={getStaticPath(`/static/images/download_salespro.svg`)} width="20px" height="20px"/></i>
                    Download App</a>
                  </span>
                )}
                { this.state.showToast &&
                  <div className={styles.item_toast} id="bottom-sticky-header">
                    <Image src={getStaticPath(`/static/images/Subtract.svg`)} width="15px" height="15px" alt={"bullet point"}/>
                    <p>Item added to the cart</p>
                  </div>
                }
                {this.props.myaccount.toast_info && this.props.myaccount.toast_info != 'An OTP has been sent to your email' && <div className={`${styles.__openToast}`} id="bottom-sticky-header">
                        <Image src={"/static/images/myprofile/updateCard.svg"} width="15px" height="15px" alt={"bullet point"}/>
                        {this.props.myaccount.toast_info}
                </div>}
                {this.props.genericReducer.toastMessage?.showToast && <Toast />}
                {this.props.myaccount.resendLink &&
                            <div className={styles.item_toast_email} id="bottom-sticky-header">
                              <Image src={getStaticPath(`/static/images/Subtract.svg`)} width="15px" height="15px" alt={"bullet point"}/>
                              <p>Verification Email Sent Successfully</p>
                            </div>
                }
              </div>
            </div>
          </div>
        </header>

        {withNav && withCart && (
          <VisibilityManager>
            {() => (
              <UIPopover
                widget={true}
                isOpen={this.state.cartWidget || this.props.cart.showCartWidget}
                onClick={(e: any) => {
                  e.preventDefault();
                  if (cart.showCartWidget && this.state.cartWidget) {
                    this.props.toggleWidget();
                    this.toggleCartWidget();
                  } else {
                    if (this.props.cart.showCartWidget) {
                      this.props.toggleWidget();
                    }
                    if (this.state.cartWidget) {
                      this.toggleCartWidget();
                    }
                  }
                  return false;
                }}
                hasHeader={true}
              />
            )}
          </VisibilityManager>
        )}
        <FooterLinks />
        {this.props.customer.signOutAlert &&
          this.props.customer.is_authenticate && (
            <UIModal
              isOpen={true}
              onOverlayClick={() => {
                this.props.setSignOutAlert(false);
              }}
              onClick={() => {
                this.props.setSignOutAlert(false);
              }}
            >
              <SignOutAlert
                onClick={() => {
                  this.props.setSignOutAlert(false);
                }}
              />
            </UIModal>
          )}
      </React.Fragment>
    );
  }
}

const FooterLinks = () => {
  return (
    <div style={{ display: "none" }}>
      <Link href="/website/sitemap">Sitemap</Link>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cart: state.cart,
    plans: state.plans,
    customer: state.customerReducer,
    showBanner: state.genericReducer.showBanner,
    withoutNavBar: state.genericReducer.withoutNavBar,
    myaccount: state.myaccountReducer,
    genericReducer: state.genericReducer
  };
};

const mapDispatchToProps = (dispatch: any) => {
  return {
    toggleWidget: () => dispatch(toggleWidget()),
    toggleScrollTop: (flag: any) => dispatch(toggleScrollTop(flag)),
    toggleHeaderSelection: (data: any) => dispatch(toggleHeaderSelection(data)),
    toggleCartAlert: (data: any) => dispatch(toggleCartAlert(data)),
    setSignOutAlert: (data: any) => dispatch(setSignOutAlert(data)),
    setShowToast: (data: any) => dispatch(showToast(data)),
    setShowBanner: (data: any) => dispatch(setShowBanner(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HeaderJSX));
