import styles from '@/styles/app-banner.module.scss'
import router from 'next/router';
import Image from '../../../../components/common/Image';
import { mobileWebTopStickeyClicked, mobileWebTopStickeyDismissed } from '../../../../tracking/segment/homePage';
import { getPageURL } from '../../../../utils/tracking/index';
import { combineParamsString, getUtmString } from '../../../../utils';



export const AppBanner = (props:any) => {
    const getAppClick = () => {
        mobileWebTopStickeyClicked({'target_link':'https://onsitego.app.link/505IXzqG3ob', ...getPageURL(props.router)})
    }
    let link = combineParamsString('https://onsitego.app.link/505IXzqG3ob',getUtmString())
    return (
        <div className={`container sticky_app_banner ${styles.container}`}>
                <div className={styles.sticky_app_banner}>
                    <div className={styles.banner_close} onClick={()=>{mobileWebTopStickeyDismissed({...getPageURL(props.router)}); props.setVisible()}}>
                        <Image src='/static/images/home/close.png' width="15" height="15" alt="close icon"/>
                    </div>
                    <div className={styles.banner_logo}>
                        <Image src='/static/images/home/app_logo.png' width="40" height="40" alt="onsitego logo"/>
                    </div>
                    <div className={styles.banner_info}>
                        <h6>Onsitego</h6>
                        <p>Download App for WOW Service</p>
                    </div>
                    <div className={styles.banner_action}>
                        <a onClick={()=>{ getAppClick(); router.push(link)}} href="#">Get App</a>
                    </div>
                </div>
        </div>
    )
}