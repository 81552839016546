import { PushToTracker  } from "../../utils/tracking";

// data contains - { banner_position, banner_link }
export const topPromotionalSliderBannerClick = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Top Promotional Slider Banner Click', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { current_location, current_location_cookie }
export const locationSelectorTap = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Location Selector Tap', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { method, final_location }
export const locationSetSuccess= ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Location Set Success', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { search_string }
export const manualLocationSearchEmpty = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Manual Location Search Empty', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}


// data contains - { search_string }
export const deviceSearchEmpty = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Device Search Empty', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { search_string }
export const deviceSearchClick = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Device Search Click', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { }
export const deviceSearchItemClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Search Item Clicked', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { item_position, item_label, cookie_city }
export const topGridClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Grid One Clicked', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { banner_link }
export const bannerOneClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Banner One Clicked', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { item_position, item_label, section_title }
export const featureCarouselClicked = ( data, number:'one' ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":`Feature Carousel Clicked ${number}`, "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { item_position, item_label, section_title }
export const gridCarouselClicked = ( data, number:'one' ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":`Grid Carousel Clicked ${number}`, "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}


// data contains - { banner_link }
export const bannerTwoClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":"Banner Two Clicked", "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { }
export const partnersScrolled = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Partners Scrolled', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { link }
export const bottomBannerClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Bottom Banner Clicked', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { link }
export const mobileWebTopStickeyClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Mobile Web Top Stickey Clicked', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { link }
export const mobileWebTopStickeyDismissed = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Mobile Web Top Stickey Dismissed', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { link }
export const heroProductSectionClicked = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Hero Product Section Clicked', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

// data contains - { page_link, category }
export const questionPopUp = ( data ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'Question Pop Up', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}

export const getLocationFromIPAbstractApi = ( data={} ) => {
    try{
        PushToTracker(
                { "gtm":false, "segment": true},
                { "segment": {"event":'ip_city', "payload": {...data}}})
    }catch{
        console.log('error while pushing to datalayer')
    }
}