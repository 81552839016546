export const SR_RESET_FIELDS = [
  "token_id",
  "serviceRequests",
  "planDetails",
  "srCreationSource",
  "categoryIssues",
  "deviceDetails",
];

export const DEVICE_DETAIL_REQUIRED_FIELDS = [
  'category', 'category_type', 'brand', 'serial', 'device_name', 'device_price'
]

export const getSortedProblems = (data: any, rootProductTypeID: any) => {
  // Sorts the fetched problem by order and adds is_valid field on it which is true
  // when root productTypeID of cover is included in problem
  // rootProductTypeID 10 is for HomeCare Plan so we have byPass issue validate for HomeCare plan
  let problems = data;
  let problems_list = Object.keys(problems).map((problem) => {
    let temp_problem = problems[problem];
    temp_problem.problem = problem;
    temp_problem.is_valid =
      problems[problem].root_product_type_ids.includes(rootProductTypeID) || rootProductTypeID === 10  ? true : false ;
    return temp_problem;
  });
  let sorted_problem_list = problems_list.sort((a, b) =>
    a.display_order > b.display_order ? 1 : -1
  );
  let null_problem_list = sorted_problem_list.filter(
    (problem) => problem.display_order === null
  );
  let non_null_problem_list = sorted_problem_list.filter(
    (problem) => problem.display_order !== null
  );

  let processed_problems_list = non_null_problem_list.concat(null_problem_list);

  return processed_problems_list;
};

export const COPAY_EXEMPTED_COVERS = ['Buy Back', 'Antivirus License']
export const COPAY_EXEMPTED_PLAN_NAMES_WHILE_RISING_SR = ['Laptop Total Care'] // for these plans we will collect copay once remotetrouble shooting activity fails