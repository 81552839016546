import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import styles from '@/components/UI/toast.module.scss';
import Image from '@/components/UI/Image';
import { setToast } from '../../../../redux/actions/genericActions';

const Toast = (props: any) => {
    const [isMobile, setMobile] = React.useState<boolean>(true)
    React.useEffect(() => {
        if(props.generic.toastMessage?.showToast){
            setTimeout(()=>{
                props.setToastRedux(null)
            },5000)
        }
        document.documentElement.style.overflowY="auto"
    },[props.generic.showToast])

    React. useEffect(() => {
        window.innerWidth < 769 ?
            setMobile(true)
            :
            setMobile(false)
    });
    return (
        <Fragment>
            {props.generic.toastMessage?.showToast && 
            <div className={`${styles.__openToast}`} id="bottom-sticky-header">
                <Image src={"/static/images/myprofile/updateCard.svg"} width={isMobile?"18px":"24px"} height={isMobile?"18px":"24px"} alt={"bullet point"} />
                <span className={styles.__toast_message}>{props.generic.toastMessage?.message}</span>
            </div>
            }
        </Fragment>
    )
}

const mapStateToProps = (state) => {
    return {
        generic: state.genericReducer
    }
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        setToastRedux: (data: any) => dispatch(setToast(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Toast);