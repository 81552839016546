export const SET_CROSS_SELL_PLANS = 'SET_CROSS_SELL_PLANS';

export const SET_CROSS_SELL_PLANS_INSTA = 'SET_CROSS_SELL_PLANS_INSTA';

export const TOGGLE_SPECIFIC_FEATURES = 'TOGGLE_SPECIFIC_FEATURES';

export const SET_HEADER_DATA = 'SET_HEADER_DATA';

export const SET_SCROLL_DATA = 'SET_SCROLL_DATA';

export const SET_CURRENT_STEP = 'SET_CURRENT_STEP';

export const SET_ALL_PLANS = 'SET_ALL_PLANS';

export const TOGGLE_HEADER_SELECTION = 'TOGGLE_HEADER_SELECTION';
