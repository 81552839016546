const CITIES_SLUG = {
  "MUMBAI": {
    city: "Mumbai",
    slug: "mumbai-thane"
  },
  "MUMBAI SUBURBAN": {
    city: "Mumbai",
    slug: "mumbai-thane"
  },
  "THANE": {
    city: "Mumbai",
    slug: "mumbai-thane"
  },
  "NAVI MUMBAI": {
    city: "Mumbai",
    slug: "mumbai-thane"
  },
  "KONKAN DIVISION": {
    city: "Mumbai",
    slug: "mumbai-thane"
  },
  "BANDRA": {
    city: "Mumbai",
    slug: "mumbai-thane"
  },
  "DADAR": {
    city: "Mumbai",
    slug: "mumbai-thane"
  },
  "VIRAR": {
    city: "Mumbai",
    slug: "mumbai-thane"
  },
  "SANTA CRUZ": {
    city: "Mumbai",
    slug: "mumbai-thane"
  },
  "BHAYANDER": {
    city: "Mumbai",
    slug: "mumbai-thane"
  },
  "VASHI": {
    city: "Mumbai",
    slug: "mumbai-thane"
  },
  "DELHI": {
    city: "Delhi NCR",
    slug: "delhi-noida-gurgaon"
  },
  "DELHI DIVISION":{
    city: "Delhi NCR",
    slug: "delhi-noida-gurgaon"
  },
  "DELHI NCR": {
    city: "Delhi NCR",
    slug: "delhi-noida-gurgaon"
  },
  "GAUTAM BUDDH NAGAR": {
    city: "Delhi NCR",
    slug: "delhi-noida-gurgaon"
  },
  "CENTRAL DELHI": {
    city: "Delhi NCR",
    slug: "delhi-noida-gurgaon"
  },
  "NORTH DELHI": {
    city: "Delhi NCR",
    slug: "delhi-noida-gurgaon"
  },
  "NORTH EAST DELHI": {
    city: "Delhi NCR",
    slug: "delhi-noida-gurgaon"
  },
  "NORTH WEST DELHI": {
    city: "Delhi NCR",
    slug: "delhi-noida-gurgaon"
  },
  "EAST DELHI": {
    city: "Delhi NCR",
    slug: "delhi-noida-gurgaon"
  },
  "WEST DELHI": {
    city: "Delhi NCR",
    slug: "delhi-noida-gurgaon"
  },
  "SOUTH DELHI": {
    city: "Delhi NCR",
    slug: "delhi-noida-gurgaon"
  },
  "SOUTH EAST DELHI": {
    city: "Delhi NCR",
    slug: "delhi-noida-gurgaon"
  },
  "SOUTH WEST DELHI": {
    city: "Delhi NCR",
    slug: "delhi-noida-gurgaon"
  },
  "PATEL NAGAR": {
    city: "Delhi NCR",
    slug: "delhi-noida-gurgaon"
  },
  "GURGAON": {
    city: "Delhi NCR",
    slug: "delhi-noida-gurgaon"
  },
  "GURUGRAM": {
    city: "Delhi NCR",
    slug: "delhi-noida-gurgaon"
  },
  "NEW DELHI": {
    city: "Delhi NCR",
    slug: "delhi-noida-gurgaon"
  },
  "GHAZIABAD": {
    city: "Delhi NCR",
    slug: "delhi-noida-gurgaon"
  },
  "NOIDA": {
    city: "Delhi NCR",
    slug: "delhi-noida-gurgaon"
  },
  "FARIDABAD": {
    city: "Delhi NCR",
    slug: "delhi-noida-gurgaon"
  },
  "OKHLA": {
    city: "Delhi NCR",
    slug: "delhi-noida-gurgaon"
  },
  "GURGAON DIVISION": {
    city: "Delhi NCR",
    slug: "delhi-noida-gurgaon"
  },
  "CHENNAI": {
    city: "Chennai",
    slug: "chennai"
  },
  "TIRUVALLUR": {
    city: "Chennai",
    slug: "chennai"
  },
  "KANCHIPURAM": {
    city: "Chennai",
    slug: "chennai"
  },
  "MARAIMALAI NAGAR": {
    city: "Chennai",
    slug: "chennai"
  },
  "HYDERABAD": {
    city: "Hyderabad",
    slug: "hyderabad"
  },
  "R.R. DISTRICT": {
    city: "Hyderabad",
    slug: "hyderabad"
  },
  "PUNE": {
    city: "Pune",
    slug: "pune"
  },
  "PUNE DIVISION": {
    city: "Pune",
    slug: "pune"
  },
  "BANGALORE URBAN": {
    city: "Bangalore",
    slug: "bangalore"
  },
  "BANGALORE RURAL": {
    city: "Bangalore",
    slug: "bangalore"
  },
  "BENGALURU URBAN": {
    city: "Bangalore",
    slug: "bangalore"
  },
  "BENGALURU RURAL": {
    city: "Bangalore",
    slug: "bangalore"
  },
  "BANGALORE DIVISION": {
    city: "Bangalore",
    slug: "bangalore"
  },
  "BENGALURU": {
    city: "Bangalore",
    slug: "bangalore"
  },
  "BANGALORE": {
    city: "Bangalore",
    slug: "bangalore"
  },
  "NAVI MUMBAI (DHIRUBHAI AMBANI KNOWLEDGE CITY)": {
    city: "Mumbai",
    slug: "mumbai-thane"
  }
}

export const DEFAULT_CITY_ORDER = [
  "Mumbai",
  "Delhi NCR",
  "Bangalore",
  "Pune",
  "Hyderabad",
  "Chennai"
]

export default CITIES_SLUG;
// export const CITIES = [
//     {
//         "city_name": "Mumbai",
//         "config": {
//             "valid_cities": ["MUMBAI", "THANE", "NAVI MUMBAI", "BHAYANDER", "VASHI"],
//             "other_cities": ["NERUL", "PANVEL", "DOMBIVLI", "TALOJA", "NALASOPARA", "GHANSOLI", "NEW PANVEL", "KALYAN", "VASAI", "VIRAR", "BHIWANDI"]
//         }
//     },
//     {
//         "city_name": "Bengaluru",
//         "config": {
//             "valid_cities": ["BANGALORE", "BENGALURU"],
//             "other_cities": []
//         }
//     },
//     {
//         "city_name": "Delhi NCR",
//         "config": {
//             "valid_cities": ["GURGAON", "GURUGRAM", "NEW DELHI", "GHAZIABAD", "NOIDA", "FARIDABAD", ],
//             "other_cities": ["BALLABHGARH", "NEW DELHI", "LUCKNOW"]
//         }
//     },
//     {
//         "city_name": "Chennai",
//         "config": {
//             "valid_cities": ["CHENNAI"],
//             "other_cities": ["KANCHIPURAM", "MARAIMALAI NAGAR"]
//         }
//     },
//     {
//         "city_name": "Ahmedabad",
//         "config": {
//             "valid_cities": ["AHMEDABAD"],
//             "other_cities": []
//         }
//     },
//     {
//         "city_name": "Hyderabad",
//         "config": {
//             "valid_cities": ["HYDERABAD"],
//             "other_cities": []
//         }
//     },
//     {
//         "city_name": "Pune",
//         "config": {
//             "valid_cities": ["PUNE"],
//             "other_cities": ["PIMPRI", "PUNE", "CHINCHWAD"]
//         }
//     }
// ]

// This Pages is For A/B Testing
export const TESTING_PAGE = ['televisions-extended-warranty-plans']
