import React, { useEffect, useState } from "react";
import { getUtmString, combineParamsString } from "../../utils";
import slug_mappings from "../../data/slug_mappings";
import ErrorIcon from "@mui/icons-material/Error";
import { getSelectedCityFromCookie,getCookieCitySlugMap } from "../../utils/cities";
import { getPageURL } from "../../utils/tracking";
import { chatbotClicked } from "../../tracking/segment/footer";

declare global {
  interface Window {
    haptikInitSettings: any;
  }
}

export const CtaQuery = (props) => {
  const [color, changeColor] = useState(false);

  const city:any = getSelectedCityFromCookie() || "";

  const handleChng = (e, plan) => {
    if (plan["isCovered"]) {
      // if(!city){
      //   setSelectedCityInCookie('Mumbai')
      // }
      changeColor(!color);
      props.changeOpen && plan.url
        ? setTimeout(
            () => (
              props.changeOpen(false),
              props.setOption(null),
              window.scrollTo(0, 0)
            ),
            500
          )
        : null;
      plan.url
        ? props.router.push(`/${combineParamsString(plan.url, getUtmString() + '&nav=search')}`)
        : null;
      props.changeDisabled(false);
      props.changeInd(e.target.id);
      props.toggleQueryModal(false);
    }
  };

  const isCityCoverd = (url: any) => {
    let isCovered:any = false;
    if(url.includes('?')){
      url = url.split('?')[0]
    }
    if (
      slug_mappings[url]?.city == null &&
      slug_mappings[url]?.["cities_covered"] == null
    ) {
      isCovered = true;
    }else {
        isCovered = getCookieCitySlugMap(slug_mappings[url])
        return isCovered;
    }
    return isCovered;
  };
  useEffect(() => {
    // loadChatBotConfig()
  }, []);


  const questionMapping = () => {
    const newQuestionList: any = [];
    props.questions.map((question) => {
      let isCovered = city ? isCityCoverd(question.url) : true;
      question["isCovered"] = isCovered;
      newQuestionList.push({ ...question });
    });
    return newQuestionList;
  };
  let questions = questionMapping(); // mapping if question covered or not
  return (
    <div className="query-modal">
      <h5 className="query-modal__title">
        Which of the following situations best describes what you're looking
        for?
      </h5>

      <ul className="query-modal__list">
        {questions &&
          questions.map((que, index) => (
            <li
              className={`query-modal__list_item ${
                index == props.ind && "selected"
              } ${!que.isCovered && "not_covered"}`}
              id={`question-${index}`}
              key={index}
              onClick={(e) => {
                handleChng(e, que);
              }}
            >
              {que.question}
              {!que.isCovered && (
                <span>
                  <ErrorIcon className="icon"></ErrorIcon>This service is not
                  available in your area
                </span>
              )}
            </li>
          ))}
      </ul>

      <a
        className="query-modal__link"
        href="https://onst.in/4dCuJA"
        target="_blank"
        rel="noopener noreferrer"
        onClick={()=>{chatbotClicked({...getPageURL()})}}
      >
        I am not sure what I want!
      </a>
      <div className="query-modal__action">
        {/* <Button disabled={props.disabled} text="View Plans" onClick={() => { props.changeOpen && plan ? setTimeout(() => (props.changeOpen(false), props.setOption(null), window.scrollTo(0, 0)), 1000) : null; plan ? props.router.push(`/${plan}`) : null; }} /> */}
      </div>
    </div>
  );
};

{
  /* <li className={index == props.ind ? "selected query-modal__list_item" : "query-modal__list_item"} id={index} key={index} onClick={(e) => { handleChng(e, que) }}> 
<div className={que.isCovered ?'question__box':'question__box__not'}>{que.question}</div> 
{!que.isCovered && <span className='alert_text'><ErrorIcon className='icon'></ErrorIcon><span>This service is not available in your area                         </span></span>}
</li>)} */
}
