import slug_mappings from '../data/slug_mappings';
import { groupBy, combineParamsString, getUtmString } from '../utils/';
import Cookies from 'js-cookie'
import CITIES_SLUG, {DEFAULT_CITY_ORDER} from '../city_constants';
import {setUserCity} from '../redux/actions/genericActions';
import store from '../redux'
import { locationSetSuccess } from '../tracking/segment/homePage';
import { getPageURL, pushToSegmentIdentify } from './tracking';
import { getCustomerCookie } from '../api/cookies';

interface Cities {
  name: string,
  url: string
}


export const isCityServiceable = (url, citySlug) => {
  return slug_mappings.hasOwnProperty(`${url}-${citySlug}`)
}

export const getCityOrder = (cities:Cities[]) => {
  let ordered_city_list:any = [];
  let city_names:string[] = cities.map(city => getCityDisplayName(city.name))

  DEFAULT_CITY_ORDER.forEach((city:string) => {
    if (city_names.includes(city)) {
      ordered_city_list.push(cities.find((c:Cities) => getCityDisplayName(c.name) === city ));
    }
  });

  return ordered_city_list;
}

export const setSelectedCityInCookie = (cityName, method='manual') => {
  cityName = getCityDisplayName(cityName.toUpperCase());
  let cookie_city = getSelectedCityFromCookie();
  locationSetSuccess({'location':cityName,'method':method,'previous_location':getSelectedCityFromCookie(),...getPageURL()})
  Cookies.set('user_city', cityName, { expires: 1 });
  ['Delhi NCR','Mumbai'].includes(cityName) ? Cookies.set('show_custom_page', true): Cookies.set('show_custom_page', false);
  store.dispatch(setUserCity(cityName))
  let user:any = getCustomerCookie()
  // call Identify event only if city is updated
  if(cityName!=cookie_city && user){
    pushToSegmentIdentify({ ...user})
  }
}

export const getCityDisplayName = (cityName) => {
  return CITIES_SLUG[cityName] ? CITIES_SLUG[cityName]["city"] : cityName
}

export const getCookieCitySlug = (cityName: any) => {
  try{
    let cookie_city = cityName || getSelectedCityFromCookie()
    let city_cons = CITIES_SLUG[cookie_city.toUpperCase()]
    return city_cons ? city_cons["slug"] : null;
  }
  catch(e){
    return null;
  }
}

export const getSelectedCityFromCookie = () => {
  return Cookies.get('user_city');
}

export const recentlySetCookie = () => {
  Cookies.set('recently_set', true)
}

export const getRecentlySetCookie = () => {
  return Cookies.get('recently_set')
}

export const clearRecentlySetCookies = () => {
  Cookies.remove('recently_set');
}

export const hasCoveredCities = (slug_mapping: object) => {
  return slug_mapping['cities_covered'] && slug_mapping['cities_covered'].length > 0;
}

export const getCookieCitySlugMap = (slug_mapping: object) => {
  let cookie_city = getSelectedCityFromCookie();
  cookie_city = cookie_city?.toUpperCase();
  if(CITIES_SLUG[cookie_city]){
    cookie_city = CITIES_SLUG[cookie_city]["city"]
  }
  let cities = []
  if (hasCoveredCities(slug_mapping)) {
    cities = slug_mapping['cities_covered'];
  }
  return cities.find((city: any) => CITIES_SLUG[city.name]["city"] === cookie_city)
}

export const getCityWiseSlugMappings = () => {
  const city_pages = Object.values(slug_mappings).filter((map: any) => map.city !== null)
  return groupBy(city_pages, 'device_name')
}

export const canShowCitySelection = (slug_mapping: object) => {
  if (slug_mapping['is_generic']) {
    let citySlug = getCookieCitySlugMap(slug_mapping)
    return (citySlug ? false : true)
  }
}

export const redirectIfServiceable = (slug: string, router: any) => {
  if(slug.includes('?')){
    slug = slug.split('?')[0]
  }
  let slug_mapping = slug_mappings[slug]
  if (hasCoveredCities(slug_mapping)) {
    redirectIfGenericPage(slug_mapping, router)
  }
}

export const redirectIfGenericPage = (slug_mapping: object, router: any) => {
  if (Object.keys(slug_mapping).length == 0) {
    return false
  }
  if (slug_mapping['is_generic']) {
    let citySlug = getCookieCitySlugMap(slug_mapping)
    if (citySlug) {
      // routerPush(router, citySlug['url'])
      router.replace(`${combineParamsString(citySlug['url'], getUtmString())}`)
    }
  }
}


export const isGenericPage = (slug:any) => {
  const urls = ['washing-machine-repair-service','chimney-hob-repair-service','laptop-repair-service','mobile-phone-repair-service','geyser-repair-service','microwave-repair-service','ro-water-purifier-service-repai','ac-service-repair','refrigerator-repair-service','printer-repair-service']
  if(urls.includes(slug)){
    return true
  }
  return false
}


export const cityLinkGenerator = (item,citySlug) => {
  let link = item.link
  if(item.isCity && citySlug){
      let s = `${item.slug}-${citySlug}`;
      let city_link = s.substring(1, s.length)
      if(slug_mappings.hasOwnProperty(city_link)){
        link = `${item.slug}-${citySlug}?${item.params}`
      }
  }
  return link
}

export const getCityPageLink = (slug: string) => {
  if(slug.includes('?')){
    slug = slug.split('?')[0]
  }
  let slug_mapping = slug_mappings[slug]
  let city = getCityDisplayName(getSelectedCityFromCookie())
  if(hasCoveredCities(slug_mapping)){
    let url = slug_mapping.cities_covered.find((plan: any) => {
      if(getCityDisplayName(plan.name)==city){
        return plan.url;
      }
    })
    return url.url;
  }
  return slug;
}