import type { AppContext, AppInitialProps, AppProps } from 'next/app'
import { Provider } from 'react-redux';
import store from '../redux';
import { useEffect, useState, useRef } from 'react';
import Head from 'next/head';
import '../styles/base.scss';
import '../styles/bootstrap-grid.scss'
import '../styles/global.scss'
import '../styles/global.modules.scss'
import "slick-carousel/slick/slick.scss";
import {SetCookie} from "../api/cookies";
import { CHECK_EXISTING_CART, VERIFY_OTP } from '../redux/actionTypes/cartActionTypes';
import { SET_WHATSAPP_AVAILABILITY, SET_USER_CITY } from '../redux/actionTypes/genericActionTypes';
import QuickAlert from '../components/common/QuickAlert';
import Config from '../config'
import { setUtmCookies, syncClarityCleverTapID } from '../utils';
import { getSelectedCityFromCookie } from '../utils/cities';
import Layout from '../components/HOC/Layout';
import { useRouter } from 'next/router';
import App from 'next/app';
import { createContext } from 'react';


export const NonceContext = createContext<string | undefined>(undefined);
type Props = { nonce?: string };

declare global {
  interface Window {
      clarity: any;
  }
}

function MyApp({ Component, pageProps, nonce }: Props & AppProps) {
  const [onetime,setOnetimecall] = useState<boolean>(false)
  const {data} = SetCookie()
  const router = useRouter()

  useEffect(() => {
    setUtmCookies(window.location.href)
  },[]);

  if(data && !onetime){
    if(data.cart && store.getState().cart.token_id === '') {
      store.dispatch({
        type: CHECK_EXISTING_CART,
        data: data.cart,
      })
    }
    if(data.verified_phone !== undefined) {
      store.dispatch({
        type: VERIFY_OTP,
        data: data.verified_phone,
      })
    }
    if (data.can_opt_in_whatsapp !== undefined) {
      store.dispatch({
        type: SET_WHATSAPP_AVAILABILITY,
        data: data.can_opt_in_whatsapp
      })
    }
    if (getSelectedCityFromCookie() !== undefined) {
      store.dispatch({
        type: SET_USER_CITY,
        data: data.userCity
      })
    }
    setOnetimecall(true)
  }

  async function initializeClevertap(): Promise<any> {
    const clevertap = await import('clevertap-web-sdk')
    clevertap.default.init(Config.CLEVER_TAP_ACCOUNT_ID, 'in1')
    clevertap.privacy.push({ optOut: false })
    clevertap.privacy.push({ useIP: false })
    return clevertap.default
  }
  
  useEffect(() => {
    initializeClevertap().then((clevertap) => {
      clevertap.spa = true
      syncClarityCleverTapID(clevertap)
    })
    // ** To Remove Web PopUp on URL Change
    if (window && window.document) {
      let ele = window.document.querySelectorAll('[id*="wiz-iframe"]')
      ele.forEach((div:any)=>{div.parentElement.remove()})
      window.document.getElementById('intentOpacityDiv')?.remove()
     }
  },[router.asPath])

  return (
    <Provider store={store}>
        <Head>
          <meta name="viewport" content="width=device-width,minimum-scale=1,maximum-scale=1,user-scalable=no"></meta>
          <link rel="preconnect" href={Config.ASSETPREFIX_PATH} />
          <link rel="dns-prefetch" href={Config.ASSETPREFIX_PATH} />
        </Head>
        <Layout {...pageProps}>
            <Component {...pageProps} /> 
          </Layout>

          <script defer nonce={nonce} data-gtm-nonce={nonce}
            dangerouslySetInnerHTML={{ __html: `(function(w,d,s,l,i){
              const gtmNonce = d.querySelector('[data-gtm-nonce]').dataset.gtmNonce;
              w[l]=w[l]||[];
              w[l].push({'nonce': gtmNonce});
              w[l].push({'gtm.start': new Date().getTime(),event:'gtm.js'});
              const f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';
              j.async=true;
              j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;
              j.setAttribute('nonce', gtmNonce);
              f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${Config.gtmCode}');`}}></script>

          <script defer nonce={nonce} type="text/javascript" dangerouslySetInnerHTML={{ __html: 
            `(function(c,l,a,r,i,t,y){c[a]=c[a]||function(){(c[a].q=c[a].q||[]).push(arguments)};
              t=l.createElement(r);t.async=1;t.src="https://www.clarity.ms/tag/"+i+"?ref=gtm2";
              t.nonce = "${nonce}";
              y=l.getElementsByTagName(r)[0];
              y.parentNode.insertBefore(t,y);
              window.clarity = clarity;
            })(window,document,"clarity","script",'${Config.CLARITY_ACCOUNT_ID}');`
          }} />
          
          <QuickAlert /> 
      </Provider>
  )
}

MyApp.getInitialProps = async (
  Context: AppContext
): Promise<Props & AppInitialProps> => {
  const props = await App.getInitialProps(Context);
  const { ctx } = Context;
  const nonce = ctx.req?.headers?.['x-nonce'] as string | undefined;
  return {
    ...props,
    nonce,
  };
};

export default MyApp
