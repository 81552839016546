import * as cartActionTypes from '../actionTypes/cartActionTypes';
import { getCartSummary } from '../../utils/cart';

interface State {
  token_id: string,
  type: string,
  items: {
    'id': string | null,
    'product_name': string | null,
    'product_sku': string | null,
    'current_price': number | null,
    'original_price': number | null,
    'from_price': number | null,
    'to_price': number | null,
    'duration': string | null,
    'start_date'?: string | null,
    'category': string | null,
    'product_type': string | null,
    'brand'?: string | null,
    'category_type': string | null,
    'cart_item_id': string | null,
    'device_price'?: Number | null,
    'device_detail': any,
    'additional_details': any,
    'type': string
    'tnc_id'?: any,
  }[],
  slot_items: {
    'id': string | null,
    'category': string | null,
    'category_type': string | null,
    'display_title': string | null,
    'quantity': number | null
  }[],
  slotSelectionData: any,
  total: number,
  discount_code: string | null,
  discount_total: number | string | null,
  discount_code_message: string | null,
  discount_alert_message:Object,
  discount_error: boolean,
  discount_is_offer: boolean,
  name: string,
  phone: string,
  email: string,
  address: string,
  updatedPincode: string
  showCartWidget: boolean,
  error: boolean,
  otpVerified: boolean,
  personalDetailsComplete: boolean,
  verifiedPhone: string | null,
  checkout_successful: any,
  details: any
  payment_complete: boolean,
  order_id: string | null,
  activation_token: string | null,
  order_items: any,
  planDetails: any,
  cartAlert: boolean,
  cartAlertData: any,
  total_items: any,
  showScrollTop: boolean,
  resetStep: boolean,
  redirectToCart: boolean,
  serviceablePopup: boolean,
  nonServiceablePincode: boolean,
  errors: Object,
  loading: string,
  resetModal: boolean,
  channel: string,
  isOtpValid: boolean,
  isPersonalDetailsValid: boolean,
  isSlotSelectionValid: boolean,
  isSlotSelectionShown: boolean,
  isCartValid: boolean,
  isSlotDisable: any,
  slotData:any,
  slotLoading:boolean,
  slotShown:boolean,
  slotApiCount:number,
  instaCount:number,
  showToast: boolean,
  edit_id: any,
  verify: any,
  isAddressValid: boolean,
  hasPageLoaded: boolean
}

const initialState: State = {
  token_id: '',
  type: '',
  items: [],
  slot_items:[],
  slotSelectionData: [],
  total: 0,
  discount_code: null,
  discount_total: null,
  discount_code_message: null,
  discount_alert_message: {},
  discount_error: false,
  discount_is_offer: false,
  name: '',
  phone: '',
  email: '',
  address: '',
  updatedPincode: '',
  showCartWidget: false,
  error: false,
  otpVerified: false,
  personalDetailsComplete: false,
  verifiedPhone: null,
  checkout_successful: false,
  details: {},
  payment_complete: false,
  order_id: null,
  activation_token: null,
  order_items: [],
  planDetails: [],
  cartAlert: false,
  cartAlertData: undefined,
  total_items: 0,
  showScrollTop: false,
  resetStep: false,
  redirectToCart: false,
  serviceablePopup: false,
  nonServiceablePincode: false,
  errors: {
  },
  loading: '',
  resetModal: false,
  channel: '',
  isOtpValid: false,
  isPersonalDetailsValid: false,
  isAddressValid: false,
  isSlotSelectionValid: false,
  isSlotSelectionShown: false,
  isCartValid: false,
  isSlotDisable: {},
  slotData:{},
  slotLoading:false,
  slotShown:true,
  slotApiCount:0,
  instaCount:0,
  showToast:false,
  edit_id: null,
  verify: false,
  hasPageLoaded: false
}

export default function cartReducer(state = initialState, action: any) {
  switch (action.type) {
    case cartActionTypes.ADD_TO_CART:
      let planDetails
      let items;
      let total = 0;
      items = action.data.items
      items = {}
      action.data.plans.forEach(plan => {
        if (items[plan.category] === undefined) {
          items[plan.category] = []
        }
        plan.plan['category_image'] = plan.category_image
        items[plan.category].push(plan.plan)
      });
      if (action.data.type && action.data.type !== 'instarepair') {
        planDetails = getPlanDetails(action.data)
      }
      total = action.data.total

      return {
        ...state,
        id: action.data.id,
        type: action.data.type,
        items,
        total,
        planDetails,
        error: false,
        total_items: action.data.total_items,
        resetStep: true,
        redirectToCart: true,
        resetModal: true,
      }

    case cartActionTypes.UPDATE_CART:
      return {
        ...state,
        ...action.data
      }

    case cartActionTypes.UPDATE_DEVICE_DETAIL:
      return {
        ...state,
        items: state.items.map((_item: any) => {
          if (_item.device_detail.id == action.device_detail.id) {
            return { ..._item, device_detail: { ...action.device_detail } }
          } else {
            return _item
          }
        })
      }

    case cartActionTypes.UPDATE_CART_ITEM:
      let updatedItems = state.items.map(_item => {
        if (_item.id == action.item.id) {
          return { ...action.item }
        } else {
          return _item
        }
      })
      let updatedSlotItems = state.slot_items.map(_item => {
        if (_item.id == action.item.id) {
          return { id: action.item.id,
            category: action.item.category,
            category_type: action.item.category_type,
            quantity: action.item.quantity,
            display_title: action.item.display_title }
        } else {
          return _item
        }
      })
      return {
        ...state,
        ...getCartSummary(updatedItems),
        items: updatedItems,
        slot_items:updatedSlotItems
      }

    case cartActionTypes.TOGGLE_WIDGET:
      return {
        ...state,
        showCartWidget: false
      }

    case cartActionTypes.CHECKOUT_SUCCESS_STATUS:
      return {
        ...state,
        checkout_successful: true,
        details: action.data
      }

    case cartActionTypes.ERROR:
      return {
        ...state,
        error: true,
      }

    case cartActionTypes.UPDATE_CART_ERRORS:
      return {
        ...state,
        errors: action.errors
      }

    case cartActionTypes.UPDATE_SERVICEABLE:
      let show_pop_up = action.show_pop_up;
      let is_all_serviceable = true;
      const newItems = state.items.map((item: any) => {
        if (action.data) {
          if (!action.data[item.id]) {
            is_all_serviceable = false;
          }
          return { ...item, serviceable: action.data[item.id] }
        }
        if (!item.serviceable) {
          is_all_serviceable = false;
        }
        return item
      })
      if (is_all_serviceable) {
        show_pop_up = false;
      }
      return {
        ...state,
        items: newItems,
        serviceablePopup: show_pop_up,
        nonServiceablePincode: !is_all_serviceable,
      }

    case cartActionTypes.SERVICE_MODAL_TOGGLE:
      return {
        ...state,
        serviceablePopup: action.show
      }

    case cartActionTypes.VERIFY_OTP:
      return {
        ...state,
        otpVerified: true,
        verifiedPhone: action.data,
        phone: action.data
      }

    case cartActionTypes.COMPLETE_PERSONAL_DETAILS:
      return {
        ...state,
        personalDetailsComplete: action.data,
      }

    case cartActionTypes.UNSET_VERIFIED_PHONE:
      return {
        ...state,
        otpVerified: false,
        verifiedPhone: null,
      }

    case cartActionTypes.DELETE_CART:
      if (action.preserveType) {
        return {
          ...initialState,
          type: state.type
        }
      }
      return {
        ...initialState
      }

    case cartActionTypes.SET_CART_TAB_CHANGE:
      // items = {}
      // action.data.plans.forEach(plan => {
      //     if(items[plan.category] === undefined) {
      //       items[plan.category] = []
      //     }
      //     plan.plan['category_image'] = plan.category_image
      //     items[plan.category].push(plan.plan)
      // });
      return {
        ...state //,items:items,total:action.data.total
      }
    case cartActionTypes.REMOVE_CART_ITEM:
      let finalItems: any = state.items?.filter(_item => _item.id != action.item_id)
      let finalSlotItems: any = state.slot_items?.filter(_item => _item.id != action.item_id)
      let customData = {}
      if (!finalItems.length) {
        customData['type'] = ""
      }
      return {
        ...state,
        ...customData,
        ...getCartSummary(finalItems),
        items: finalItems,
        slot_items:finalSlotItems
      }

    case cartActionTypes.CHECK_EXISTING_CART:
      
      if(!action.data.discount_message&&action.data.error_messsage){action.data.discount_message=action.data.error_messsage}
      let tmp=action.data.items || [];
      let tmp2:any=[];
      tmp.forEach(item => {
        tmp2.push({id:item.id,category: item.category, category_type: item.category_type, quantity: item.quantity, display_title: item.display_title })
      });
      return {
        ...state,
        ...action.data,
        redirectToCart: action.redirectToCart? true: false,
        showToast:action.showToast,
        loading:false,
        slot_items:tmp2
      }

    case cartActionTypes.UPDATE_FRONTEND_CART:
      let data: any = action.data
      if ('plans' in data) {
        items = {}
        if (action.data.plans && action.data.plans.length > 0) {
          action.data.plans.forEach(plan => {
            if (items[plan.category] === undefined) {
              items[plan.category] = []
            }
            plan.plan['category_image'] = plan.category_image
            items[plan.category].push(plan.plan)
          });
          if (state.type !== 'instarepair') {
            planDetails = getPlanDetails(action.data)
          }
        } else {
          items = {}
          planDetails = []
        }
      } else {
        items = state.items
      }
      total = data.total !== undefined ? data.total : state.total

      return {
        ...state,
        ...data,
        items,
        type: action.data.type !== undefined ? action.data.type : state.type,
        planDetails,
        total,
        discount_total: action.data.total_discount,
        error: false,
      }

    case cartActionTypes.PAYMENT_STATUS:
      return {
        ...state,
        payment_complete: true,
        activation_token: action.data.token_id,
        order_id: action.data.order_id,
        order_items: action.data.plans,
      }

    case cartActionTypes.APPLY_DISCOUNT:

      return {
        ...state,
        total: action.data.total,
        discount_code: action.data.discount_code,
        discount_total: action.data.total_discount,
        discount_error: false
      }

    case cartActionTypes.SET_DISCOUNT_ERROR:
      return {
        ...state,
        discount_message: action.discount_message
      }

    case cartActionTypes.DISCOUNT_ERROR:
      return {
        ...state,
        discount_error: true,
        discount_code_message: action.data.message,
      }

    case cartActionTypes.REMOVE_DISCOUNT:
      return {
        ...state,
        total: action.data.cart_total,
        discount_code: null,
        discount_total: null,
        discount_code_message: null,
        discount_error: false,
      }
      
    case cartActionTypes.TOGGLE_CART_ALERT:
      return {
        ...state,
        cartAlertType: action.data.toggleType,
        cartAlert: action.data.toggle,
        cartAlertData: action.data,
      }
    
    case cartActionTypes.TOGGLE_SCROLL_TOP:
      return {
        ...state,
        showScrollTop: action.data
      }

    case cartActionTypes.TOGGLE_RESET_STEP:
      return {
        ...state,
        resetStep: action.data,
      }

    case cartActionTypes.TOGGLE_REDIRECTION:
      return {
        ...state,
        redirectToCart: false,
      }
    case cartActionTypes.CHANGE_UPSELL_CROSS_SELL_MODAL:
      return {
        ...state,
        add_additional: action.show
      }

    case cartActionTypes.TOGGLE_LOADING:
      return {
        ...state,
        loading: action.toggle
      }
    case cartActionTypes.SET_CUSTOMER_DETAILS:
      return {
        ...state,
        name: action.data.name,
        email: action.data.email,
      }

    case cartActionTypes.RESET_MODAL:
      return {
        ...state,
        resetModal: action.reset,
      }

    case cartActionTypes.OTP_VALID:
      return {
        ...state,
        isOtpValid: action.data,
      }

    case cartActionTypes.PERSONAL_DETAILS_VALID:
      return {
        ...state,
        isPersonalDetailsValid: action.data,
      }

    case cartActionTypes.IS_ADDRESS_VALID:
        return {
          ...state,
          isAddressValid: action.data,
        }

    case cartActionTypes.SLOT_SELECTION_VALID:
      return {
        ...state,
        isSlotSelectionValid: action.data,
      }

    case cartActionTypes.SLOT_SELECTION_SHOWN:
      return {
        ...state,
        isSlotSelectionShown: action.data,
      }

    case cartActionTypes.IS_CART_VALID:
      return {
        ...state,
        isCartValid: action.data,
      }
    case cartActionTypes.TOGGLE_CART_ALERT_AGENT:
      return {
        ...state,
        cartAlertType: action.data.toggleType,
        cartAlert: action.data.toggle,
        cartAlertData:action.data.cartAlertData,
      }
    
    case cartActionTypes.SHOW_TOAST:
      return {
        ...state,
        showToast: action.data,
      }

    case cartActionTypes.UPDATE_PINCODE:
      return {
        ...state,
        updatedPincode: action.data,
      }

    case cartActionTypes.UPDATED_SLOT_DISABLE:
      return {
        ...state,
        isSlotDisable: action.data,
      }

    case cartActionTypes.UPDATED_SLOT_DATA:
      return {
        ...state,
        slotData: action.data,
      }

    case cartActionTypes.SLOT_LOADING:
      return {
        ...state,
        slotLoading: action.data,
      }
    
    case cartActionTypes.SLOT_SHOWN:
    return {
      ...state,
      slotShown: action.data,
    }

    case cartActionTypes.SLOT_INSTA_ITEM:
      return {
        ...state,
        instaCount: action.data,
      }

    case cartActionTypes.SLOT_API_COUNT:
      if (action.data==0){  
        return {
          ...state,
          slotApiCount: 0,
        }
      }
      else{
        return {
          ...state,
          slotApiCount: state.slotApiCount+1
        }
      }

      case cartActionTypes.ADD_SLOT_SELECTION_DATA:
      return {
        ...state,
        slotSelectionData: [...sortSlots([...state.slotSelectionData, action.data], state.items)],
        slotApiCount: state.slotApiCount - 1
      }

      case cartActionTypes.UPDATE_SLOT_SELECTION_DATA:
        return {
          ...state,
          slotSelectionData: [...action.data],
        }
      
      case cartActionTypes.UPDATE_ADDRESS_ID:
        return{
          ...state,
          edit_id: action.data
        }

        case cartActionTypes.CLEAR_CART_TYPE:
          return {
            ...state,
            type: ""
          }
      case cartActionTypes.UPDATE_VERIFY_MODE:
        return{
          ...state,
          verify:action.data
        }
      case cartActionTypes.HAS_PAGE_LOADED:
        return{
        ...state,
        hasPageLoaded: action.data
      }
    default:
      return state
  }
}

const getPlanDetails = (data: any) => {
  let planDetails: any = []
  let temp_item: any;
  data.plans.forEach(item => {
    temp_item = {}
    if (item.acType) {
      temp_item['acType'] = item.acType
    }
    if (item.acCapacity) {
      temp_item['acCapacity'] = item.acCapacity
    }
    if (item.wpType) {
      temp_item['wpType'] = item.wpType
    }
    if (item.devicePrice) {
      temp_item['devicePrice'] = item.devicePrice
    }
    if (item.brand) {
      temp_item['brand'] = item.brand
    }
    if (item.category_image) {
      temp_item['category_image'] = item.category_image
    } else {
      temp_item['category_image'] = ""
    }
    temp_item['plan'] = item.plan
    planDetails.push(temp_item)
  })
  return planDetails;
}

const sortSlots = (unsortedSlotSelectionDate, cartItems) => {
  let sortedSlotSelectionData:any = []
  let unavailable: any = [];
  let tempOrderCount = 0;
  let orderHash:{[key: string]: number} = cartItems.reduce((tempSlotOrder, item) => {
      if (item.group === "insta-repair") {
          if (!tempSlotOrder[item.category_type]) {
              tempSlotOrder[item.category_type] = tempOrderCount;
              tempOrderCount++;
          }
      }
      return tempSlotOrder;
  }, {})
  unsortedSlotSelectionDate.forEach((itemData) => {
    console.log(itemData)
      if(itemData?.isDateAvailable){
          let index = orderHash[itemData['items'][0]['category_type']]
          sortedSlotSelectionData[index] = itemData;
      } else{
          unavailable.push(itemData);
      }
      console.log(itemData);
  })
  return [...unavailable, ...sortedSlotSelectionData].filter(slotItem => slotItem)
}
