export const TrackCall = (event, payload,retry_count=0) => {
    try {
        if(window.clevertap)
        {
            window.clevertap.event.push(event, payload)
            return
        }else{
          if(retry_count<3){
            setTimeout(()=>{
              TrackCall(event,payload,retry_count+=1)
            },1000)
          }
        }
    }
    catch(err:any) {
        console.log(`Unable to push to segment track call. ${err.toString()}`)
    }
  }


export const PageCall = (event, payload) => {
  try {
      if(window.clevertap)
      {
          // CT not accept page call so using track function to get page view event
          window.clevertap.event.push(event, payload) 
          // Segment buit-in page call to get page data to GA
          // window.clevertap.page(page_name) 
      }
  }
  catch(err:any) {
      console.log(`Unable to push to segment page call. ${err.toString()}`)
  }
}

export const pageCallSegment = (_page_name) => {
  try {
      if(window.clevertap)
      {
          // window.clevertap.page(page_name) 
      }
  }
  catch(err:any) {
      console.log(`Unable to push to segment page call Segment. ${err.toString()}`)
  }
}


export const IdentifyCall = (traits) => {
  // Clevertap OnUserLogin Format - "Site":{"Name":"","Identity":}

    try {
        if(window.clevertap)
        {
          window.clevertap.onUserLogin.push({...traits})
        }
    }
    catch(err:any) {
        console.log(`Unable to push to segment identify call. ${err.toString()}`)
    }
}
 
// Reset User Call - To Reset User from local storage - segment and clevetap store user data to local cookies
export const ResetUser = () => {
    try {
      if(window.clevertap)
      {
        window.clevertap.clear()
      }
    }
    catch(err:any) {
      console.log(`Unable to call Reset User. ${err.toString()}`)
    }
}