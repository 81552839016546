import React from 'react'
import HeaderJSX from './HeaderJSX';

class Header extends React.Component<any> {
  render(){
    return <HeaderJSX {...this.props} />
  }
}

export default Header;
