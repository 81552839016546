export const SHOW_QUICKALERT = "SHOW_QUICKALERT"

export const HIDE_QUICKALERT = "HIDE_QUICKALERT"

export const TOGGLE_HAPTIK_ICON = 'TOGGLE_HAPTIK_ICON'

export const SET_WHATSAPP_AVAILABILITY = 'SET_WHATSAPP_AVAILABILITY'

export const SET_USER_CITY = 'SET_USER_CITY'

export const SET_SHOW_BANNER = 'SET_SHOW_BANNER'

export const HIDE_NAVIGATION_BAR = 'HIDE_NAVIGATION_BAR'

export const SET_CITY_CHANGE_POPUP = 'SET_CITY_CHANGE_POPUP'

export const SET_PINE_LABS_CAMPAIGN = "SET_PINE_LABS_CAMPAIGN"

export const SET_DISPLAY_SKU_CONFIG = "SET_DISPLAY_SKU_CONFIG"

export const SET_TOAST = "SET_TOAST"
