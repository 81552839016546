
import * as instaActionTypes from '../actionTypes/instaActionTypes';

  interface State {
  plans: any
  order_total : number
}

  const initialState:State = {
    plans: {},
    order_total: 0
  }

export default function instarepairReducer(state=initialState, action:any) {
    switch (action.type) {
        case instaActionTypes.INCREMENT_QUANTITY:
            let newState = {...state}
            if(!Object.keys(state.plans).length){
                var plans = JSON.parse(sessionStorage.getItem('planstates') || '{"plans":{},"order_total":0}')
                if(plans){
                    newState = {...plans}
                }
            }
            if(newState.plans[action.categorytype]){
                if (newState.plans[action.categorytype][action.category]){
                    if(newState.plans[action.categorytype][action.category][action.sku]){
                        newState.plans[action.categorytype][action.category][action.sku].quantity += 1
                    }else{
                        newState.plans[action.categorytype][action.category][action.sku] = {}
                        newState.plans[action.categorytype][action.category][action.sku]['quantity'] = 1
                    }
                    newState.plans[action.categorytype][action.category]['order_total'] += action.price
                    newState.plans[action.categorytype]['order_total'] += action.price
                    newState.order_total += action.price
                    sessionStorage.setItem('planstates', JSON.stringify(newState));
                    return {...newState}
                }else{
                    newState.plans[action.categorytype][action.category] = {}
                    newState.plans[action.categorytype][action.category][action.sku] = {}
                    newState.plans[action.categorytype][action.category][action.sku]['quantity'] = 1
                    newState.plans[action.categorytype][action.category]['order_total'] = action.price
                    newState.plans[action.categorytype]['order_total'] += action.price
                    newState.order_total += action.price
                    sessionStorage.setItem('planstates', JSON.stringify(newState));
                    return {...newState}
                }
            }else{
                newState.plans[action.categorytype] = {}
                newState.plans[action.categorytype][action.category] = {}
                newState.plans[action.categorytype][action.category][action.sku] = {}
                newState.plans[action.categorytype][action.category][action.sku]['quantity'] = 1
                newState.plans[action.categorytype][action.category]['order_total'] = action.price
                newState.plans[action.categorytype]['order_total'] = action.price
                newState.order_total += action.price
                sessionStorage.setItem('planstates', JSON.stringify(newState));
                return {...newState}
            }
        case instaActionTypes.DECREMENT_QUANTITY:

            newState = {...state}
            if(newState.plans[action.categorytype] && newState.plans[action.categorytype][action.category] && newState.plans[action.categorytype][action.category][action.sku]){
                if(newState.plans[action.categorytype][action.category][action.sku]){
                    newState.plans[action.categorytype][action.category]['order_total'] -= action.price
                    newState.plans[action.categorytype][action.category][action.sku].quantity -= 1
                    newState.plans[action.categorytype]['order_total'] -= action.price
                }
                newState.order_total -= action.price
        }

            sessionStorage.setItem('planstates', JSON.stringify(newState));
            return {...newState}

        default:
        return state;
    }
}
