import TagManager from 'react-gtm-module';

export const pushToDatalayer = (event, payload) => {
  let composedDataLayer = {
      event: event,
      ...payload
  }
  try {
    TagManager.dataLayer({
      dataLayer: composedDataLayer
    })
  }
  catch(err:any) {
    console.log(`Unable to push to datalayer. ${err.toString()}`)
  }
}
