import * as customerActionTypes from "../actionTypes/customerActionTypes";

interface State {
  name:string,
  first_name: string;
  last_name: string;
  email: string;
  display_email: string;
  phone: string;
  phone_verified: boolean;
  address: any;
  landmark: string;
  city: string;
  pincode: string;
  state: string;
  current_step_login: number;
  is_authenticate: boolean;
  customer_token: string;
  signOutAlert: boolean;
  userCartId:'',
  email_activation_pending:boolean,
  customer_conflict:boolean,
  edit: boolean,
  phone_prefilled: boolean
}

const initialState: State = {
  first_name: "",
  email: "",
  phone: "",
  address: [],
  landmark: "",
  city: "",
  pincode: "",
  state: "",
  last_name: "",
  display_email:"",
  phone_verified:false,
  current_step_login: 1,
  is_authenticate: false,
  customer_token: "",
  signOutAlert: false,
  userCartId:'',
  email_activation_pending:false,
  customer_conflict:false,
  name:"",
  edit: false,
  phone_prefilled: false
};

export default function customerReducer(state = initialState, action: any) {

  switch (action.type) {
    case customerActionTypes.LOGIN_CUSTOMER:
      return {
        ...state,
        ...action.data,
        is_authenticate: action.data ? true : false,
        name: ((action.data?.first_name || "") + ' ' + (action.data?.last_name || "")).trim()
      };
    case customerActionTypes.SET_CURRENT_STEP_CUSTOMER:
      return {
        ...state,
        current_step_login: action.data,
      };
    case customerActionTypes.SET_CUSTOMER_DETAILS:
      return {
        ...state,
        ...action.data,
        name: ((action.data?.first_name || "") + ' ' + (action.data?.last_name || "")).trim()
      };
    case customerActionTypes.SET_IS_AUTHENTICATE:
      return {
        ...state,
        is_authenticate: action.data,
      };
    case customerActionTypes.SET_SIGNOUT_ALERT:
      return {
        ...state,
        signOutAlert: action.data,
      };
    case customerActionTypes.SET_CUSTOMER_CART_ID:
        return {
          ...state,
          userCartId: action.data,
        };
    case customerActionTypes.UPDATE_ADDRESS:
        let newAdd = state.address
        let ind = newAdd.findIndex(item => item.id === action.id)        
        newAdd[ind]['address'] = action.data.address;
        newAdd[ind]['landmark'] = action.data.landmark;
        newAdd[ind]['label'] = action.data.label;
        newAdd[ind]['lat'] = action.data.lat;
        newAdd[ind]['lng'] = action.data.lng;
        newAdd[ind]['is_verified'] = true;
        return {
          ...state,
          address: [...newAdd]
        };
    case customerActionTypes.CREATE_NEW_ADDRESS:
        return {
          ...state,
          address : [...state.address, action.data]
        };
    case customerActionTypes.UPDATE_CART_EDIT_MODE:
      return {
          ...state,
          edit: action.data
      }
    case customerActionTypes.SIGNOUT:
      return {
          ...initialState
      }
      case customerActionTypes.SET_CUSTOMER_PHONE_PREFILLED:
        return {
          ...state,
          phone: action.data,
          phone_prefilled: true
        };
    default:
      return state;
  }
}
