export const APPBANNER = {
    1: {
        title: "Get Best Offers on Our App",
        description:"Get 10% Off on Your First Order",
        image:"onlymobile"
    },
    2: {
        title: "Manage Plans at Your Fingertips",
        description:["Get Service Reminders","Easy Request Tracking","Quick Doorstep Assistance"],
        image:"imagemobile"
    },
    3: {
        title: "Raise Request Easily on App & Relax",
        description:["Get Service Reminders","Easy Request Tracking","Quick Doorstep Assistance"],
        image:"imagemobile"
    },
    4: {
        title: "Get WOW Experience on Our App",
        description:["Same Day Service & Repairs","Get Expert Assistance","Hassle Free Experience"],
        image:"onlymobile"
    },
    5: {
        title: "Get WOW Experience on Our App",
        description:["Same Day Service & Repairs","Get Expert Assistance","Best Offers & Discounts"],
        image:"onlymobile"
    }
}

export const links = {
    plans: "onsitego.app.link/wRLVfpjhUyb",
    profile: "onsitego.app.link/rZ7djzohUyb",
    orders: "onsitego.app.link/dRwyrmthUyb",
    requests: "onsitego.app.link/wB5TjFb23yb",
    ordersid: "onsitego.app.link/dRwyrmthUyb",
    activation: "onsitego.app.link/wRLVfpjhUyb",
}

export const emailIndicator = {
    1: "An OTP will be sent to this email for verification",
    2: "An OTP has been sent to this email",
    3: "This email is already registered. We have sent an OTP on this email for verification",
}

export const ACTIVE_PLANS = ["Active", "Activation Pending", "Under Brand Warranty", "Inspection Pending", "NOT STARTED"]

export const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];

export const noPlan = {
    image: "no-plans.svg",
    link: "/",
    desc: "Purchase a plan to enjoy our services on your devices & appliances",
    title: "You don’t have any active plans yet!",
    titleActivation: "You don’t have any plans to activate!",
    label: "explore plans",
};

export const noPlanBlockSales = {
    image: "no-plans.svg",
    link: "/",
    desc: "Purchase a plan to enjoy our services on your devices & appliances",
    title: "You don’t have any plans to activate!",
    label: "explore plans",
};

export const noRequest = {
    image: "no-requests.svg",
    link: "/account/plans",
    desc: "Book repair services or raise service requests on your purchased plans",
    title: "You have not raised any request yet",
    label: " RAISE A SERVICE REQUEST",
};

export const noRequestAndPlan = {
    image: "no-requests.svg",
    link: "/",
    desc: "Purchase a plan to enjoy our services on your devices & appliances ",
    title: "You have not raised any request yet",
    label: "Explore plans",
};

export const noOrder = {
    image: 'no-orders.svg',
    link: '/',
    desc: "Book repair services or shop protection and maintenance plans for your devices",
    title: "We are waiting to serve you!",
    label: "start exploring"
}

export const sideBarList =[
    {name: "My Plans", url : "plans", image: "plans.svg"}, 
    {name: "Activate Plan", url : "activation", image: "activate.svg"},
    {name: "My Profile", url : "profile", image: "profile.svg"}, 
    {name: "My Requests", url : "requests", image: "requests.svg"}, 
    {name: "Orders & Bookings", url : "orders", image: "orders.svg"}, 
    {name: "Help & Support", url : "help", image: "whatsapp.png"}
]
export const requestBlockSalesTouchPointWithPlan = {
    image: 'no-requests.svg',
    link: '/account/plans?params=ordersummary',
    desc: "Book repair services or raise service requests on your purchased plans",
    title: "You have not raised any request yet",
    label: " RAISE A SERVICE REQUEST",
}

export const orderBlockSalesTouchPointWithPlan = {
    image: 'no-orders.svg',
    link: '/account/plans?params=ordersummary',
    desc: "Book repair services or shop protection and maintenance plans for your devices",
    title: "We are waiting to serve you!",
    label: "GO TO MY PLANS"
}

export const requestBlockSalesTouchPointWithNoPlan = {
    image: 'no-requests.svg',
    link: '/account/plans?params=ordersummary',
    desc: "Book repair services or raise service requests on your purchased plans",
    title: "You have not raised any request yet",
    label: "GO TO MY PLANS",
}
