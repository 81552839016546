import Config from '../../config'
import { cleverTapTrigger } from '../../tracking/segment'
import { GetCall,PostCall } from '../helper'


const URLS = {
    "PRODUCTS": Config.BASE_BUILD_API_URL + "/home_products",
    "FEATUREDCATEGORIES": Config.BASE_BUILD_API_URL + "/featured-categories",
    "QUESTIONS": Config.BASE_BUILD_API_URL + "/home-questions",
    "PROTECTIONPLANS": Config.BASE_BUILD_API_URL + "/common_pages/protection_plans/all_devices?show_all_devices=show",
    "AMC": Config.BASE_BUILD_API_URL + "/common_pages/annual_maintenance_contract/all_devices",
    "HOMEFEATURES": Config.BASE_BUILD_API_URL + "/home-features",
    "CATEGORYLINKS": Config.BASE_BUILD_API_URL + "/devices_and_plans",
    "INSTAREPAIRPLANS": Config.BASE_BUILD_API_URL + '/common_pages/insta-reair-devices',
    "BANNERIMAGE": Config.BASE_API_URL + '/banner/',
    "LOG_SEARCH_KEYWORD": Config.BASE_API_URL + '/log-search-keyword',
    "POST_SEARCH_LOCATION": Config.BASE_API_URL + '/search-word',
    "FETCH_LOCATION_DETAILS": Config.BASE_API_URL + '/fetch-details',
    "SEND_APP_LINK": Config.BASE_API_URL + '/send_app_link',
    "GET_GEOLOCATION": Config.BASE_API_URL + '/get_geolocation',
    "GRID_DATA": Config.BASE_API_URL + '/grid_data/',
    "GET_CITY_FROM_IP": Config.BASE_API_URL + '/city_from_ip'

}
export const GetProducts = async (data?: any) => {
    return await GetCall(URLS.PRODUCTS, data, Config.DEFAULT_HEADER)
}

export const GetCategory = async (data?: any) => {
    return await GetCall(URLS.FEATUREDCATEGORIES, data, Config.DEFAULT_HEADER)
}

export const GetQuestions = async (data?: any) => {
    return await GetCall(URLS.QUESTIONS, data, Config.DEFAULT_HEADER)
}

export const GetProtectionPlans = async (data?: any) => {
    return await GetCall(URLS.PROTECTIONPLANS, data, Config.DEFAULT_HEADER)
}

export const GetAmc = async (data?: any) => {
    return await GetCall(URLS.AMC, data, Config.DEFAULT_HEADER)
}

export const GetFeatures = async (data?: any) => {
    return await GetCall(URLS.HOMEFEATURES, data, Config.DEFAULT_HEADER)
}
export const GetCategoryLinks = async (data?: any) => {
    return await GetCall(URLS.CATEGORYLINKS, data, Config.DEFAULT_HEADER)
}

export const GetInstaRepairPlans = async (data?: any) => {
    return await GetCall(URLS.INSTAREPAIRPLANS, data, Config.DEFAULT_HEADER)
}

export const GetBannerImage = async (data?: any) => {
    return await GetCall(URLS.BANNERIMAGE, data, Config.DEFAULT_HEADER)
}

export const LogSearchKeyword = async(data?:any) => {
    return PostCall(URLS.LOG_SEARCH_KEYWORD, {data:data, application: 'website'}, Config.DEFAULT_HEADER)
}

export const SearchLocation = async(data?:any) => {
    cleverTapTrigger("placesapi", {...data})
    return PostCall(URLS.POST_SEARCH_LOCATION, {data:data, application: 'website'}, Config.DEFAULT_HEADER)
}

export const FetchLocation = async(data?:any) => {
    return PostCall(URLS.FETCH_LOCATION_DETAILS, {data:data, application: 'website'}, Config.DEFAULT_HEADER)
}

export const SendAppLink = async(data?:any) => {
    return PostCall(URLS.SEND_APP_LINK, {data:data, application: 'website'}, Config.DEFAULT_HEADER)
}

export const GetGeoLocation = async (data?: any) => {
    return await GetCall(URLS.GET_GEOLOCATION, data, Config.DEFAULT_HEADER)
}

export const GetGridData = async (data?: any) => {
    return await GetCall(URLS.GRID_DATA, data, Config.DEFAULT_HEADER)
}
export const GetLocationFromIP = async () => {
    return await GetCall(URLS.GET_CITY_FROM_IP, {}, Config.DEFAULT_HEADER)
}
