export const SET_ORDER_DATA = "SET_ORDER_DATA"

export const CLEAR_ORDER_DATA = "CLEAR_ORDER_DATA"

export const DOWNLOAD_CERTIFICATE_POPUP = "DOWNLOAD_CERTIFICATE_POPUP"

export const SET_STEP = "SET_STEP"

export const SET_IS_VERIFIED = "SET_IS_VERIFIED"

export const SET_WHATSAPP_SUBSCRIBED = "SET_WHATSAPP_SUBSCRIBED"

export const SET_ORDER_PHONE = "SET_ORDER_PHONE"
