import * as myaccountActionTypes from "../actionTypes/myaccountActionTypes";

interface State {
  current_step: string,
  userPlans: any,
  userOrders: any,
  myRequests: any,
  is_nameEdit: boolean,
  is_emailEdit: boolean,
  toast_info: string,
  customerDetails: any,
  addressPopupType: any,
  loader: boolean,
  expand: boolean,
  is_copay: any,
  edit: any,
  isDeleteAddressPopup: any,
  deleteAddressId: any,
  resendLink:boolean,
  is_AddressPopupOPen: boolean,
  has_plans:boolean,
  orderIdList: any,
  activationPlans: any,
  amazonOrderPopup: boolean
}

const initialState: State = {
  current_step: "My Plans", // 1. myplans 2.myprofile 3.myrequests 4.myorders 5.helpsupport
  userPlans: [],
  userOrders: [],
  myRequests: [],
  is_nameEdit: false,
  is_emailEdit: false,
  toast_info: "",
  customerDetails: {
    address: {
      address: null,
      pincode: null,
      city: null,
      state: null,
      address2: null,
      landmark: null,
      label: null,
      id: null,
      lat: null,
      lng: null
    }
  },
  addressPopupType: null,
  loader: false,
  expand: false,
  is_copay: null,
  edit: false,
  isDeleteAddressPopup: false,
  deleteAddressId: '',
  resendLink: false,
  is_AddressPopupOPen: false,
  has_plans: false,
  orderIdList: [],
  activationPlans: [],
  amazonOrderPopup: false
};

export default function myaccountReducer(state = initialState, action: any) {
  switch (action.type) {
    case myaccountActionTypes.SET_CURRENT_STEP_MYACCOUNT:
      return {
        ...state,
        current_step: action.data,
      };
    case myaccountActionTypes.GET_PLANS:
      return ({
        ...state,
        userPlans: [...action.data]
      })
    case myaccountActionTypes.GET_ORDERS:
      return ({
        ...state,
        userOrders: [...action.data]
      })
    case myaccountActionTypes.EDIT_NAME:
      return ({
        ...state,
        is_nameEdit: !state.is_nameEdit
      })
    case myaccountActionTypes.EDIT_EMAIL:
      return ({
        ...state,
        is_emailEdit: !state.is_emailEdit
      })
    case myaccountActionTypes.TOAST_INFO:
      return ({
        ...state,
        toast_info: action.data
      })
    case myaccountActionTypes.UPDATE_ADDRESS_DETAILS:
      let temp_list = JSON.parse(JSON.stringify(state.customerDetails))
      temp_list['address'] = action.data.address
      return {
        ...state,
        customerDetails: {
          ...state.customerDetails,
          ...temp_list
        }
      }
    // case myaccountActionTypes.UPDATE_ADDRESS_DETAILS:

    //   return {
    //     ...state,
    //     customerDetails: {
    //       ...state.customerDetails,
    //       ...temp_list
    //     }
    //   }

    case myaccountActionTypes.LOADER:
      return ({
        ...state,
        loader: action.data
      })
    case myaccountActionTypes.IS_COPAY:
      return ({
        ...state,
        is_copay: action.data,
      })
    case myaccountActionTypes.UPDATE_MACCOUNT_EDIT_MODE:
      return ({
        ...state,
        edit: action.data,
      })
    case myaccountActionTypes.DELETE_ADDRESS_POPUP:
      return ({
        ...state,
        isDeleteAddressPopup: action.isDeleteAddressPopup,
        deleteAddressId: action.deleteAddressId
      })
    case myaccountActionTypes.MY_REQUESTS_DATA:
      return ({
        ...state,
        myRequests: [...action.data]
      })
      case myaccountActionTypes.EMAIL_RESEND_LINK:
      return ({
        ...state,
        resendLink: action.data,
      })
      case myaccountActionTypes.IS_ADDRESSPOPUP_OPEN:
        return ({
          ...state,
          is_AddressPopupOpen: action.data,
        })
      case myaccountActionTypes.HAS_PLANS:
        return ({
          ...state,
          has_plans: action.data,
        })
      case myaccountActionTypes.ORDER_ID:
        return ({
          ...state,
          orderIdList: action.data
        })
      case myaccountActionTypes.SET_ACTIVATION_PLANS:
        return ({
          ...state,
          activationPlans: action.data
        })
      case myaccountActionTypes.AMAZON_ORDER_CONFIRMATION:
        return ({
          ...state,
          amazonOrderPopup: action.data
        })
    default:
      return state;
  }
}
