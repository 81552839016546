import * as customerActionTypes from "../actionTypes/customerActionTypes";

export const updateCustomerData = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: customerActionTypes.LOGIN_CUSTOMER,
      data: data,
    });
  };
};

export const setCurrentStepCustomer = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: customerActionTypes.SET_CURRENT_STEP_CUSTOMER,
      data: data,
    });
  };
};

export const setCustomerDetails = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: customerActionTypes.SET_CUSTOMER_DETAILS,
      data: data,
    });
  };
};

export const setIsAuthenticate = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: customerActionTypes.SET_IS_AUTHENTICATE,
      data: data,
    });
  };
};

export const setSignOutAlert = (data:any) => {
  return (dispatch) => {
    dispatch({
      type: customerActionTypes.SET_SIGNOUT_ALERT,
      data:data,
    });
  };
};

export const setCustomerCartId = (data:any) => {
  return (dispatch) => {
    dispatch({
      type: customerActionTypes.SET_CUSTOMER_CART_ID,
      data:data,
    });
  };
};

export const updateAddress = (data: any, id: any) => {

  return (dispatch) => {
    dispatch({
      type: customerActionTypes.UPDATE_ADDRESS,
      data: data,
      id: id
    });
  };
};

export const createNewAddress = (data: any) => {
  
  return (dispatch) => {
    dispatch({
      type: customerActionTypes.CREATE_NEW_ADDRESS,
      data: data,
    });
  };
};

export const changeCartEdit = (data: boolean) => {

  return dispatch => {
      dispatch({
          type: customerActionTypes.UPDATE_CART_EDIT_MODE,
          data,
      })
  }
}

export const resetCustomer = () => {
  return dispatch => {
      dispatch({
          type: customerActionTypes.SIGNOUT,
      })
  }
}

export const setCustomerPhonePrefilled = (data:any) => {
  return dispatch => {
      dispatch({
          type: customerActionTypes.SET_CUSTOMER_PHONE_PREFILLED,
          data
      })
  }
}
