import Config from "../../config";
import { getApplication } from "../../utils";
import { PostCall, PatchCall, GetCall, DeleteCall } from "../helper";

const BASE_API_URL = Config.BASE_API_URL;
const DEFAULT_HEADER = Config.DEFAULT_HEADER;

const URLS = {
  SEND_OTP: BASE_API_URL + "/v3/otp/send_otp",
  VERIFY_OTP: BASE_API_URL + "/v3/otp/verify_otp",
  PARTIAL_UPDATE: BASE_API_URL + "/v3/customer/partial_update",
  IS_VALID_CUSTOMER: BASE_API_URL + "/customers/check_customer_existence",
  SIGN_IN: BASE_API_URL + "/customers/login",
  SIGN_UP: BASE_API_URL + "/customers",
  SEND_RESET_PASSWORD_LINK:
    BASE_API_URL + "/customers/send_reset_password_link",
  UPDATE_CUSTOMER_DETAILS: BASE_API_URL + "/customers/update",
  VERIFY_EMAIL_TOKEN: Config.BASE_API_URL + "/v3/customer/activate_email",
  VERIFY_RESET_TOKEN: Config.BASE_API_URL + "/customers/verify_token",
  RESET_PASSWORD: Config.BASE_API_URL + "/customers/reset_password",
  SEND_EMAIL_VERIFICATION: Config.BASE_API_URL + "/v3/customer/resend_activation",
  SIGN_OUT: Config.BASE_API_URL + '/customers/signout',
  REPLACE_CART: Config.BASE_API_URL + "/v3/cart/replace_cart",
  SEND_EMAIL_OTP: BASE_API_URL + "/v3/otp/send_email_otp",
  VERIFY_EMAIL_OTP: BASE_API_URL + "/v3/otp/verify_email_otp",
  RATINGS: BASE_API_URL + "/v3/customer/ratings",
  DELETE_ADDRESS: BASE_API_URL + "/myaccount/address",
  MY_REQUEST: BASE_API_URL + "/v3/tracker/search",
  MY_ORDER: BASE_API_URL + "/v3/customer/my_orders",
  MY_ORDER_DETAILS: BASE_API_URL + "/v3/customer/order",
  ATTACH_PLAN: BASE_API_URL + "/v3/customer/attach_plan",
  WEB_RENER_LOGIN: BASE_API_URL + "/v3/otp/web_render_login",
};

export const SendOTP = async (data: any) => {
  return await PostCall(URLS.SEND_OTP, data, DEFAULT_HEADER);
};

export const appLogin = async (data: any) => {
  return await PostCall(URLS.WEB_RENER_LOGIN, data, DEFAULT_HEADER);
}
export const VerifyOTP = async (data: any) => {
  return await PostCall(URLS.VERIFY_OTP, data, DEFAULT_HEADER);
};

export const VerifyEmailToken = async (data?: any) => {
  return await PostCall(`${URLS.VERIFY_EMAIL_TOKEN}`, { 'application': 'website', data: data }, Config.DEFAULT_HEADER)
}

export const VerifyResetToken = async (data?: any) => {
  return await PostCall(`${URLS.VERIFY_RESET_TOKEN}`, data, Config.DEFAULT_HEADER)
}

export const PasswordReset = async (data?: any) => {
  return await PostCall(`${URLS.RESET_PASSWORD}`, data, Config.DEFAULT_HEADER)
}

export const SendEmailVerification = async (data?: any) => {
  return await PostCall(`${URLS.SEND_EMAIL_VERIFICATION}`, { data: data, 'application': 'website' }, Config.DEFAULT_HEADER)
}

export const SignOut = async (data?: any) => {
  return await GetCall(`${URLS.SIGN_OUT}`, data, Config.DEFAULT_HEADER)
}

export const PartialUpdate = async (id, data?: any) => {
  return await PatchCall(`${URLS.PARTIAL_UPDATE}/${id}`, { data: data, application: 'website' }, Config.DEFAULT_HEADER)
}

export const SendEmailOTP = async (data?: any) => {
  return await PostCall(`${URLS.SEND_EMAIL_OTP}`, data, Config.DEFAULT_HEADER)
}

export const VerifyEmailOTP = async (data?: any) => {
  return await PostCall(`${URLS.VERIFY_EMAIL_OTP}`, data, Config.DEFAULT_HEADER)
}

export const SendRatings = async (data: any) => {
  return await PostCall(URLS.RATINGS, data, DEFAULT_HEADER);
};

export const DeleteAddress = async (data: any) => {
  return await DeleteCall(URLS.DELETE_ADDRESS, data, DEFAULT_HEADER);
};

export const MyRequestData = async (data: any) => {
  return await GetCall(`${URLS.MY_REQUEST}`, data, Config.DEFAULT_HEADER)
}

export const MyOrdersData = async (data: any) => {
  return await GetCall(`${URLS.MY_ORDER}`, data, Config.DEFAULT_HEADER)
}

export const MyOrderDetailsData = async (id, data: any) => {
  return await GetCall(`${URLS.MY_ORDER_DETAILS}/${id}`, data, Config.DEFAULT_HEADER)
}

export const AttachPlan = async (data?: any) => {
  return await PostCall(`${URLS.ATTACH_PLAN}`, data, Config.DEFAULT_HEADER)
}
