import {isAMC} from './index'

const getExtraDeviceDataAMC = device => {
  let data = {
    expandDeviceDetails: true,
    expandScheduleDetails: true
  }
  const {device_name, brand, device_purchased_date, manufacturer_warranty} = device;
  if(!!device_name && !!brand && !!device_purchased_date && !!manufacturer_warranty){
    data['expandDeviceDetails'] = false
  }
  return data;
}


const getExtraDeviceDataProtection = device => {
  let data = {
    expandDeviceDetails: true,
    expandDeviceUpload: true,
    expandUpsellDetails: true
  }

  const {serial, model, device_price, brand, documents, manufacturer_warranty, device_condition} = device;
  if(!!serial && !!model && !!device_price && !!brand && !!manufacturer_warranty && !!device_condition){
    data['expandDeviceDetails'] = false;
  }
  let device_invoice_documents = documents.filter(item=> item.document_type=='0')
  let device_image_documents = documents.filter(item=> item.document_type=='1')
  if(!!device_invoice_documents.length && !!device_image_documents.length){
    data['expandDeviceUpload'] = false;
  }
  return data;
}



export const getExtraDeviceData = device => {
  let _isAMC = false;
  for(let plan of device.plans){
    if(isAMC(device, plan)){
      _isAMC = true;
      break
    }
  }
  if(_isAMC){
    return getExtraDeviceDataAMC(device)
  }else{
    return getExtraDeviceDataProtection(device)
  }
}