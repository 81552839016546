import * as genericActionTypes from '../actionTypes/genericActionTypes';

export const hideQuickAlert = () => {
  return dispatch => {
    dispatch({
      type: genericActionTypes.HIDE_QUICKALERT
    })
  }
}

export const showQuckAlert = (message:any) => {
    return dispatch => {
      dispatch({
        type: genericActionTypes.SHOW_QUICKALERT,
        message: message
      })
    }
  }

export const setUserCity = (data:any) => {
  return dispatch => {
    dispatch({
      type: genericActionTypes.SET_USER_CITY,
      data: data
    })
  }
}

export const setShowBanner = (data:any) => {
  return dispatch => {
    dispatch({
      type: genericActionTypes.SET_SHOW_BANNER,
      data: data
    })
  }
}

export const hideNavigationBar = (data:any) => {
  return dispatch => {
    dispatch({
      type: genericActionTypes.HIDE_NAVIGATION_BAR,
      data: data
    })
  }
}
export const setCityChangePopUpAction = (data:any) => {
  return dispatch => {
    dispatch({
      type: genericActionTypes.SET_CITY_CHANGE_POPUP,
      data: data
    })
  }
}
export const setPineLabsCampaign = (data:any) => {
  return dispatch => {
    dispatch({
      type: genericActionTypes.SET_PINE_LABS_CAMPAIGN,
      data: data
    })
  }
}

export const setToast = (data:any) => {
  return dispatch => {
    dispatch({
      type: genericActionTypes.SET_TOAST,
      data: data
    })
  }
}
