export const SET_CURRENT_STEP_MYACCOUNT = "SET_CURRENT_STEP_MYACCOUNT"

export const GET_PLANS = "GET_PLANS"

export const GET_ORDERS = "GET_ORDERS"

export const EDIT_NAME = "EDIT_NAME"

export const EDIT_EMAIL = "EDIT_EMAIL"

export const TOAST_INFO = "TOAST_INFO"

export const UPDATE_ADDRESS_DETAILS = "UPDATE_ADDRESS_DETAILS"

export const LOADER = "LOADER"

export const IS_COPAY = "IS_COPAY"

export const UPDATE_MACCOUNT_EDIT_MODE = "UPDATE_MACCOUNT_EDIT_MODE"

export const DELETE_ADDRESS_POPUP = "DELETE_ADDRESS_POPUP"

export const MY_REQUESTS_DATA = "MY_REQUESTS_DATA"

export const EMAIL_RESEND_LINK = "EMAIL_RESEND_LINK"

export const IS_ADDRESSPOPUP_OPEN = "IS_ADDRESSPOPUP_OPEN"

export const HAS_PLANS = "HAS_PLANS"

export const ORDER_ID = "ORDER_ID"

export const SET_ACTIVATION_PLANS = "SET_ACTIVATION_PLANS"

export const AMAZON_ORDER_CONFIRMATION = "AMAZON_ORDER_CONFIRMATION"
