import React from "react";
import Image from "next/legacy/image";
import {getStaticPath} from '../../../../utils';

const myLoader = ({ src, width, quality }) => {
  return `${getStaticPath(src)}?w=${width}&q=${quality || 75}`;
}

const MyImage = props => {
  const {src, width, quality, className, ...all} = props;
  return (
    <Image
      loader={myLoader}
      src={getStaticPath(props.src)}
      width={props.width}
      quality={props.quality}
      {...all}
      className={className}
      style={{
        maxWidth: "100%",
        height: "auto"
      }} />
  );
}

export default MyImage;