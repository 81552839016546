import * as planActionTypes from '../actionTypes/planActionTypes';

interface State {
  type:string,
  details: {
    category?:string,
    acType?:string,
    wpType?:string,
    acCapacity?:string,
    brand?:string,
    devicePrice?:number,
    plan:{
      'product_name':string,
      'product_sku':string,
      'current_price':number,
      'original_price':number,
      'from_price':number,
      'to_price':number,
      'duration':string,
      'cart_item_id':any,
      'sku':any,
    }
  }[],
  crossSellData?: any,
  crossSellDataInsta: any,
  selectedCrossSellDevice?:any,
  plan_specific_features: string | undefined,
  headerData:any,
  planStep:number,
  allPlans:any,
  hasScrolled:boolean,
  showScrollAfterElement: string | undefined,
  showHaptik:any
}
const initialState:State = {
  type: '',
  details: [],
  crossSellData: null,
  crossSellDataInsta: null,
  selectedCrossSellDevice: null,
  plan_specific_features: undefined,
  headerData: {},
  planStep: 1,
  allPlans:[],
  hasScrolled:false,
  showScrollAfterElement: undefined,
  showHaptik:false
}

export default function planReducer(state=initialState, action:any) {
  switch (action.type) {
    case planActionTypes.SET_CROSS_SELL_PLANS:
      return {
        ...state,
        crossSellData: action.data
      }
    
    case planActionTypes.SET_CROSS_SELL_PLANS_INSTA:
      return {
        ...state,
        crossSellDataInsta: action.data
      }

    case planActionTypes.TOGGLE_SPECIFIC_FEATURES:
      return {
        ...state,
        plan_specific_features: action.data,
      }

    case planActionTypes.SET_HEADER_DATA:
      return {
        ...state,
        headerData:action.data,
      }

    case planActionTypes.SET_SCROLL_DATA:
      return {
        ...state,
        showScrollAfterElement:action.data,
      }

    case planActionTypes.SET_CURRENT_STEP:
      return {
        ...state,
        planStep: action.data,
      }

    case planActionTypes.SET_ALL_PLANS:
      return {
        ...state,
        allPlans:action.data,
      }

    case planActionTypes.TOGGLE_HEADER_SELECTION:
      return {
        ...state,
        hasScrolled:action.data,
      }
    default:
      return state;
  }
}
