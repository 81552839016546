import * as orderActionTypes from '../actionTypes/orderActionTypes';

const initState:any = {
  id: "",
  order_id:"",
  total: "",
  email: "",
  phone: "",
  plans: [],
  invoices: [],
  tncs: [],
  image: '',
  type:'',
  name:'',
  whatsapp_subscribed:'',
  phone_verified:'',
  payment_date:'',
  download_certificate_popup:false,
  download_pending:false,
  download_osid:'',
  step:1
}

export default function orderReducer(state=initState, action:any) {
  switch (action.type) {
    case orderActionTypes.SET_ORDER_DATA:
      let data = action.data
      return {
        ...state,
        id: data.id,
        order_id: data.order_id,
        total: data.amount,
        email: data.email || data.display_email,
        phone: data.phone,
        plans: data.plans,
        product_type: data.product_type,
        channel: data.channel,
        invoices: data.invoices,
        tncs: data.tncs,
        image: data.image,
        token: data.token || data.token_id,
        type: data.type,
        name: data.name,
        whatsapp_subscribed: data.whatsapp_subscribed,
        phone_verified: data.phone_verified,
        payment_date: data.payment_date
      }

    case orderActionTypes.CLEAR_ORDER_DATA:
      return {
        ...initState
      }
    
    case orderActionTypes.DOWNLOAD_CERTIFICATE_POPUP:
      return {
        ...state,
        ...action.data
      }
    
    case orderActionTypes.SET_STEP:
      return {
        ...state,
        step: action.data
      }

    case orderActionTypes.SET_IS_VERIFIED:
      return {
        ...state,
        phone_verified: action.data
      }

      case orderActionTypes.SET_WHATSAPP_SUBSCRIBED:
        return {
          ...state,
          whatsapp_subscribed: action.data
        }
    
        case orderActionTypes.SET_ORDER_PHONE:
          return {
            ...state,
            phone: action.data,
            phone_verified: true,
            whatsapp_subscribed: true
          }

    default:
      return state
  }
}
