import { getSelectedCityFromCookie } from '../cities';
import  { pushToDatalayer } from './gtmTracking';
import { TrackCall, PageCall, IdentifyCall, ResetUser } from './segmentTracking';

export const PushToTracker = (identifier, payload) => {
    if(identifier["gtm"])
    {
        pushToDatalayer("",payload["gtm"]) // push to gtm datalayer
    }
    if(identifier["segment"])
    {
        let event = payload.segment.event
        TrackCall(event, payload["segment"]["payload"]) // push to segment
    }
}

export const PushToPage = (identifier, payload) => {
  if(identifier["gtm"])
    {
        pushToDatalayer("",payload["gtm"]) // push to gtm datalayer
    }
  if(identifier["segment"])
    {
        let event = payload.segment.event
        PageCall(event, payload["segment"]["payload"]) // push to page call segment
    }
}


export const pushToSegmentIdentify = (user:any) => {
  let user_id = user.id||'';
  let name = user.name ? `${user.name}` : user.first_name && user.last_name?`${user.first_name} ${user.last_name}`:''
  let cookie_city = getSelectedCityFromCookie(); 
  let address_info = {}
  if (user.address_info){
    address_info = {
      'current_city':user.address_info.city,
      'current_pincode':user.address_info.pincode,
      'current_state': user.address_info.state,
      'current_address': user.address_info.address,
      'current_landmark': user.address_info.landmark
    }
  }
  if (cookie_city){
    address_info = {
      ...address_info,
      'current_location':cookie_city
    }
  }
  let traits = {
    "Site":{
      'Name':name,
      'Identity':user_id,
      'MSG-whatsapp':user.receive_whatsapp_updates|| false,
      ...address_info
    }
  }
  // Identify Call
  IdentifyCall(traits)
}

export const resetUserSegment = (user) => {
  let ajs_user_id = getClevertapCustomerID();
  if(!(ajs_user_id=='null' || ajs_user_id==null) && ajs_user_id!=`"${user.id}"`){
    ResetUser() 
  }
}

// get page URl 
export const getPageURL = (router?) => {
  let data:any = {'page_link':''}
  data['page_link'] = window.location.href;
  if(router){
    data['config'] = router.query?.config ? router.query.config : ''
    return data
  } 
  return data
}

// TO Get Page View Payload
export const getPageViewData = () => {
  let data:any = {}
  if(typeof window !== 'undefined'){
    data['url'] = window.location.href;
    data['path'] = window.location.pathname;
    data['title'] = window.document.title;
    data['params'] = window.location.search;
  }
  return data;
}

// To get Clevertap user id store in local storage
export const getClevertapCustomerID = () => {
  let WZRK_K = window.localStorage.getItem('WZRK_K');
  let customer_id = WZRK_K && JSON.parse(decodeURIComponent(WZRK_K)).id;
  return customer_id;
}

// To get product_name for CT events
export const getProductName = item => {
  if (item.group === 'insta-repair' || item.group === 'amc') {
    return item.title || item.product_title;
  } else if (
    item.group === 'extended-warranty' ||
    item.group === 'screen-protection' ||
    item.group === 'spills-and-drops'  ||
    item.group === 'iprotect'          ||
    item.group === 'total-protection'
  ) {
    return `${item.product_type} - ${item.duration}`;
  } else if (item.group === 'homecare') {
    return 'Homecare';
  }
  return item.title || item.product_title;
};

export const getProtectionPlanName = (productType, category) => {
  if (productType == 'screen-protection-plans')
    return 'SP'
  else if(productType == 'damage-protection-plans')
    return 'SDP'
  else if(productType == 'iprotect')
    return 'iProtect Gold'
  else if(productType == 'total-protection' && category == 'mobile-phones')
    return 'Mobile Total Care'
  else if(productType == 'total-protection' && category == 'laptops')
    return 'Laptop Total Care'
  else
    return 'Extended Warranty'
}