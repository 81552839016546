import Config from '../../config';
import { GetCall, PostCall, PatchCall, DeleteCall, GetCallWithError } from '../helper'
import Cookies from 'js-cookie'
import { generateTabId, getApplication } from '../../utils';
import { getSelectedCityFromCookie } from '../../utils/cities';

const URLS = {
  "CART": Config.BASE_API_URL + "/v3/cart",
  "RESET_CART": Config.BASE_API_URL + "/v3/cart/reset",
  "CART_ITEM": Config.BASE_API_URL + "/v3/cart/item",
  "CART_ITEMS": Config.BASE_API_URL + "/v3/cart/items",
  "SEND_CART_LINK": Config.BASE_API_URL + "/v3/cart/send_link",
  "DEVICE_DETAIL": Config.BASE_API_URL + "/v3/cart/device_detail",
  "SEND_OTP": Config.BASE_API_URL + "/v3/otp/send_otp",
  "SEND_VOICE_OTP": Config.BASE_API_URL + "/v3/otp/send_voice_otp",
  "VERIFY_OTP": Config.BASE_API_URL + "/v3/otp/verify_otp",
  "CHECKOUT": Config.BASE_API_URL + "/v3/order/checkout",
  "SUCCESS_CHECKOUT": Config.BASE_API_URL + "/v3/order/success",
  "FAILURE_CHECKOUT": Config.BASE_API_URL + "/v3/order/failure",
  "EDIT_CART": Config.BASE_API_URL + "/v3/shop/edit_cart",
  "REMOVE_ITEM": Config.BASE_API_URL + "/v3/shop/remove_item",
  "FETCH_CITY": Config.BASE_API_URL + "/v3/shop/fetch_city",
  "SERVICEABLE": Config.BASE_API_URL + "/v3/cart/serviceable",
  "GET_EDIT_OPTIONS": Config.BASE_API_URL + "/v3/shop/get_edit_options",
  "EDIT_CART_PROTECTION": Config.BASE_API_URL + "/v3/shop/edit_cart_protection",
  "APPLY_DISCOUNT": Config.BASE_API_URL + "/v3/cart/apply_discount",
  "REMOVE_DISCOUNT": Config.BASE_API_URL + "/v3/cart/remove_discount",
  "ORDER_SUMMARY": Config.BASE_API_URL + "/v3/order/summary",
  "SUMMARY_DOWNLOADS": Config.BASE_API_URL + "/v3/order/download",
  "SEND_WEBPAGE_VISITED_TO_LSQ": Config.BASE_API_URL + "/v3/cart/send_webpage_visited_to_lsq",
  "CREATE_LEAD": Config.BASE_API_URL + "/v3/cart/create_lead",
  "GET_PAYMENT_DATA": Config.BASE_API_URL + "/v3/order/payment_link_details",
  "PAYMENT_LINK_SUCCESS": Config.BASE_API_URL + "/v3/order/payment_link_success",
  "SLOT_AVAILIABLE": Config.BASE_API_URL + "/crm/slot_availability",
  "UPDATE_CUSTOMER_DETAILS_CART": Config.BASE_API_URL + "/v3/cart/update_cart",
  "ADDRESS": Config.BASE_API_URL + "/v3/customer/address",
  "UPDATE_CART_CITY": Config.BASE_API_URL + "/v3/cart/update_cart_city",
  "SEND_CERTIFICATE": Config.BASE_API_URL + "/v3/activation/send_certificate_wa",
}

export const CreateCart = async (data?: any) => {
  data = { tabId: generateTabId(), ...data, city:getSelectedCityFromCookie() }
  return await PostCall(URLS.CART, { data: data, application: getApplication() }, Config.DEFAULT_HEADER)
}

export const AddToCart = async (data?: any) => {
  return await PostCall(URLS.CART_ITEM, { data: {city: getSelectedCityFromCookie(), ...data }, application: getApplication() }, Config.DEFAULT_HEADER)
}

export const UpdateDeviceDetails = async (data?: any) => {
  return await PostCall(`${URLS.CART_ITEMS}`, { data: data, application: 'website' }, Config.DEFAULT_HEADER)
}

export const PatchCartItems = async (id, data?: any) => {
  return await PatchCall(`${URLS.CART_ITEMS}/${id}`, { data: data, application: 'website' }, Config.DEFAULT_HEADER)
}

export const PatchCartItem = async (id, data?: any) => {
  return await PatchCall(`${URLS.CART_ITEM}/${id}`, { data: data, application: 'website' }, Config.DEFAULT_HEADER)
}

export const PatchDeviceDetail = async (id, data?: any) => {
  return await PatchCall(`${URLS.DEVICE_DETAIL}/${id}`, { data: data, application: 'website' }, Config.DEFAULT_HEADER)
}

export const UpdateCart = async (pk: string, data: any) => {
  return await PatchCall(`${URLS.CART}/${pk}`, data, Config.DEFAULT_HEADER)
}

export const RemoveCartItem = async (id: any, data: any) => {
  return await DeleteCall(`${URLS.CART_ITEM}/${id}`, { data: data, application: 'website' }, Config.DEFAULT_HEADER)
}


export const GetCart = async (id: string) => {
  let campaign_cookie = Cookies.get('special_campaign') && JSON.parse(Cookies.get('special_campaign')) || []
  const Header = { "UTMCAMPAIGNS":campaign_cookie.toString(), ...Config.DEFAULT_HEADER }
  return await GetCall(`${URLS.CART}/${id}`, { 'application': 'website' }, Header)
}

export const SendOTP = async (data: any) => {
  return await PostCall(URLS.SEND_OTP, data, Config.DEFAULT_HEADER);
}

export const VerifyOTP = async (data: any) => {
  return await PostCall(URLS.VERIFY_OTP, data, Config.DEFAULT_HEADER);
}

export const Checkout = async (data: any) => {
  let campaign_cookie = Cookies.get('special_campaign') && JSON.parse(Cookies.get('special_campaign')) || []
  const Header = { "UTMCAMPAIGNS":campaign_cookie.toString(), ...Config.DEFAULT_HEADER }
  return await PostCall(URLS.CHECKOUT, { ...data, application: getApplication() }, Header);
}

export const SuccessCheckout = async (data: any) => {
  let response = await PostCall(URLS.SUCCESS_CHECKOUT, { ...data, application: getApplication() }, Config.DEFAULT_HEADER);
  if (response?.data.verified_phone) {
    if (Config.DEBUG) {
      // expires = 1 is equal to 1 day..  1/24 = 1 hour and 1/48 is half day
      Cookies.set('verified_phone', response.data.verified_phone, { expires: 1 / 48 })
    }
  }
  return response
}

export const FailureCheckout = async (data: any) => {
  return await PostCall(URLS.FAILURE_CHECKOUT, { ...data, application: getApplication() }, Config.DEFAULT_HEADER);
}

export const ApplyDiscount = async (data: any) => {
  return await PostCall(URLS.APPLY_DISCOUNT, data, Config.DEFAULT_HEADER)
}

export const RemoveDiscount = async (data: any) => {
  return await PostCall(URLS.REMOVE_DISCOUNT, data, Config.DEFAULT_HEADER)
}

export const SendVoiceOtp = async (data: any) => {
  return await PostCall(URLS.SEND_VOICE_OTP, data, Config.DEFAULT_HEADER)
}

export const GetOrderSummary = async (data: any, pk: string) => {
  return await GetCall(`${URLS.ORDER_SUMMARY}/${pk}`, data, Config.DEFAULT_HEADER)
}

export const GetPaymentDetails = (token: any, data: any) => {
  return GetCall(`${URLS.GET_PAYMENT_DATA}/${token}`, data, Config.DEFAULT_HEADER)
}

export const Serviceable = async (token_id: any, data: any) => {
  return await GetCall(`${URLS.SERVICEABLE}/${token_id}`, data, Config.DEFAULT_HEADER)
}

export const GetInvoiceUrl = (token, type) => {
  return `${URLS.SUMMARY_DOWNLOADS}/${token}?application=website&type=${type}`
}

export const SendCartLink = (id, data: any) => {
  return PostCall(`${URLS.SEND_CART_LINK}/${id}`,
    { ...data, application: getApplication() },
    Config.DEFAULT_HEADER)
}

export const SendWebpageVisitedToLsq = (id) => {
  return GetCall(`${URLS.SEND_WEBPAGE_VISITED_TO_LSQ}/${id}`,
    { application: getApplication() },
    Config.DEFAULT_HEADER)
}

export const CreateLead = (data: any) => {
  console.log(URLS.CREATE_LEAD)
  return PostCall(URLS.CREATE_LEAD, data, Config.DEFAULT_HEADER)
}

export const PaymentLinkCheckout = async (data: any) => {
  return await PostCall(URLS.CHECKOUT, data, Config.DEFAULT_HEADER);
}

export const PaymentLinkSuccess = (data: any) => {
  return PostCall(URLS.PAYMENT_LINK_SUCCESS, data, Config.DEFAULT_HEADER)
}

export const GetAvailableSlot = async (data:any) => {
  return await GetCallWithError(`${URLS.SLOT_AVAILIABLE}`, data, Config.DEFAULT_HEADER)
}

export const updateCustomerDetailsCart = async (data:any) => {
  return await PostCall(URLS.UPDATE_CUSTOMER_DETAILS_CART, { data:data, application: getApplication() }, Config.DEFAULT_HEADER)
}

export const CreateAddress = async (data: any) => {
  return await PostCall(URLS.ADDRESS, { data:data, application: getApplication() }, Config.DEFAULT_HEADER);
}

export const UpdateAddress = async (data: any, id: string) => {
  const HEADER = {
    ...Config.DEFAULT_HEADER,
    usertoken: data.customer_token
  };
  return await PatchCall(`${URLS.ADDRESS}/${id}`, { data:data, application: getApplication() }, HEADER);
}

export const UpdateCartCity = (id, data) => {
  return PostCall(`${URLS.UPDATE_CART_CITY}/${id}`, data, Config.DEFAULT_HEADER)
}

export const SendCertificateWhatsapp = async (data: any) => {
  return await PostCall(URLS.SEND_CERTIFICATE, data, Config.DEFAULT_HEADER)
}
