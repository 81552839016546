import *  as profileActionTypes from '../actionTypes/profileActionTypes'

interface State {
    username:string,
    first_name: string, 
    last_name: string, 
    email: string, 
    phone: string, 
    permissions: any,
    teams: any,
}

const initialState:State = {
    username: '',
    first_name: '',
    last_name: '',
    email: '',
    phone:  '',
    permissions: [],
    teams: []
}

export default function profileReducer (state=initialState, action:any) {
    switch (action.type) {
        case profileActionTypes.LOGIN_USER:
            return {
                ...state,
                ...action.data
            }
        default: 
            return state
    }
}
