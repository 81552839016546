const getLayoutProps = (page, config) => {
  const channel = "website"
  let props = {}
  switch(page) {
    case 'home-page': {
      props = {
        headerProps: {
          channel: channel,
          page: page,
          IsCoBranded: false,
          withNav: true,
          IsInverse: true,
          withCart: true,
          showChatIcon: true,
          withProfile: true,
        },
        showChatIcon: true,
        ScrollTillElementID: "service-selector",
      }
      break;
    }
    case 'about-us': {
      props = {
        headerProps: {
          channel: channel,
          page: page,
          IsCoBranded: false,
          withNav: true,
          withProfile: true,
          IsInverse: true
        }
      }
      break;
    }
    case 'registration': {
      props = {
        headerProps: {
          channel: channel,
          page: page,
          IsCoBranded: false,
          withNav: true,
          IsInverse: true,
          withCart: true,
          withProfile: true,
          showChatIcon: true
        },
          showChatIcon: true
      }
      break;
    }
    case 'careers': {
      props = {
        headerProps: {
          channel: channel,
          page: page,
          IsCoBranded: false,
          withNav: true,
          withProfile: true,
          IsInverse: true
        }
      }
      break;
    }
    case 'common-insta': {
      props = {
        headerProps: {
          channel: channel,
          page: page,
          IsCoBranded: false,
          withNav: true,
          withCart: true,
          IsInverse: false,
          showChatIcon: true,
          withProfile: true,
        }
      }
      break;
    }
    case 'media': {
      props = {
        headerProps: {
          channel: channel,
          page: page,
          IsCoBranded: false,
          withNav: true,
          withProfile: true,
          IsInverse: true
        },
          showChatIcon: true,
          ScrollTillElementID: 'post-3'
      }
      break;
    }
    case 'mobile-plan-comparison': {
      props = {
        headerProps: {
          channel: channel,
          page: page,
          IsCoBranded: false,
          withNav: true,
          withCart: true,
          withProfile: true,
          IsInverse: true
        },
        noFooter: true
      }
      break;
    }
    case 'privacy-policy': {
      props = {
        headerProps: {
          channel: channel,
          page: page,
          IsCoBranded: false,
          withNav: true,
          withProfile: true,
          IsInverse: true
        }
      }
      break;
    }
    case 'protection-plans': {
      props = {
        headerProps: {
          channel: channel,
          page: page,
          IsCoBranded: false,
          withNav: true,
          withCart: true,
          withProfile: true,
          IsInverse: true,
          showChatIcon: true,
        }
      }
      break;
    }
    case 'selfserve': {
      props = {
        headerProps: {
          channel: channel,
          page: page,
          IsCoBranded: false,
          withNav: true,
          IsInverse: true,
          withCart: true,
          withProfile: true,
          showChatIcon: true,
        },
          showChatIcon: true,
      }
      break;
    }
    case 'terms-of-service': {
      props = {
        headerProps: {
          channel: channel,
          page: page,
          IsCoBranded: false,
          withNav: true,
          withProfile: true,
          IsInverse: true
        }
      }
      break;
    }
    case 'terms-of-use': {
      props = {
        headerProps: {
          channel: channel,
          page: page,
          IsCoBranded: false,
          withNav: true,
          withProfile: true,
          IsInverse: true
        }
      }
      break;
    }
    case 'sitemap': {
      props = {
        headerProps: {
          channel: channel,
          page: page,
          IsCoBranded: false,
          withNav: true,
          withProfile: true,
          IsInverse: true
        }
      }
      break;
    }
    case 'what-is-covered': {
      props = {
        headerProps: {
          channel: channel,
          page: page,
          IsCoBranded: false,
          withNav: true,
          withProfile: true,
          IsInverse: true
        }
      }
      break;
    }
    case 'plan-page': {
      props = {
        headerProps: {
          channel: channel,
          page: page,
          IsCoBranded: false,
          withNav: true,
          withCart: true,
          IsInverse: false,
          productType: config.productType,
          withProfile: true,
          showChatIcon: true,
        },
          showChatIcon: true,
          ScrollTillElementID: 'coverage-details'
      }
      break;
    }
    case 'questions': {
      props = {
        headerProps: {
          channel: channel,
          page: page,
          IsCoBranded: false,
          withNav: false,
          IsInverse: true,
          showChatIcon: true,
        },
        noFooter: true
      }
      break;
    }
    case 'privacy-policy': {
      props = {
        headerProps: {
          channel: channel,
          page: page,
          IsCoBranded: false,
          withNav: true,
          withProfile: true,
          IsInverse: true
        }
      }
      break;
    }
    case 'write-to-us': {
      props = {
        headerProps: {
          channel: channel,
          page: page,
          IsCoBranded: false,
          withNav: true,
          IsInverse: true,
          withProfile: true,
          showChatIcon: true,
        },
        showChatIcon: true,
      }
      break;
    }
    case 'login': {
      props = {
        headerProps: {
          channel: channel,
          page: page,
          IsCoBranded: false,
          withNav: true,
          IsInverse: true,
          withProfile: true,
          showChatIcon: true,
        },
        showChatIcon: true,
        ScrollTillElementID: "service-selector",
      }
      break;
    }
    case 'myaccount': {
      props = {
        headerProps: {
          channel: channel,
          page: page,
          IsCoBranded: false,
          withNav: true,
          IsInverse: true,
          showChatIcon: true,
          withCart: true,
          withProfile: true,
        },
        showChatIcon: true,
        ScrollTillElementID: "service-selector",
      }
      break;
    }
    case 'TRAddress': {
      props = {
        headerProps: {
          channel: channel,
          page: page,
          IsCoBranded: false,
          withNav: false,
          withProfile: false,
          IsInverse: true,
          withCart: false,
          TRAddress: true,
        },
        noFooter: true
      }
      break;
    }
    case 'salespro-app-for-retailers': {
      props = {
        headerProps: {
          channel: channel,
          page: page,
          IsCoBranded: false,
          withNav: false,
          IsInverse: true,
          withCart: false,
          showChatIcon: true,
          withProfile: false,
          salesproPage: true
        },
        showChatIcon: false
      }
      break;
    }
    case 'activation': {
      props = {
        headerProps: {
          channel: channel,
          page: page,
          IsCoBranded: false,
          withNav: true,
          IsInverse: true,
          showChatIcon: true,
          withCart: true,
          withProfile: true,
        },
        showChatIcon: true,
        ScrollTillElementID: "service-selector",
      }
      break;
    }

    case 'document-upload': {
      props = {
        headerProps: {
          channel: channel,
          page: page,
          IsCoBranded: false,
          withNav: false,
          IsInverse: true,
          showChatIcon: true,
          withCart: false,
          withProfile: false,
        },
        showChatIcon: false,
        ScrollTillElementID: "service-selector",
      }
      break;
    }
    default: {
      props = {
        headerProps: {
          channel: channel,
          page: page,
          IsCoBranded: false,
          withNav: true,
          IsInverse: true,
          withCart: true,
          withProfile: true,
        },
          showChatIcon: true,
          ScrollTillElementID: "service-selector",
      }
      break;
    }
  }
  props = {
    headerProps: props['headerProps'] || {},
    footerProps: props['footerProps'] || {},
    noFooter: props['noFooter'] || false,
    showChatIcon: props['showChatIcon'] || false,
    ScrollTillElementID: props['ScrollTillElementID'] 
  }
  return props;
};

export default getLayoutProps;
