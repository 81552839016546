export const BEGIN_SEARCH = "BEGIN_SEARCH"

export const BEGIN_LOAD_SR_DETAIL = "BEGIN_LOAD_SR_DETAIL"

export const SEARCH_SERVICE_REQUESTS = "SEARCH_SERVICE_REQUESTS"

export const LOAD_SERVICE_REQUEST_DETAIL = "LOAD_SERVICE_REQUEST_DETAIL"

export const SR_ADDRESS_DETAILS = "SR_ADDRESS_DETAILS"

export const BEGIN_LOADER = "BEGIN_LOADER"

export const CURRENT_STEP = "CURRENT_STEP"

export const RESET_ADDRESS = "RESET_ADDRESS"

export const ADDRESS_COMPONENT = "ADDRESS_COMPONENT"

export const ERRORS = "ERRORS"

export const TEMP_LAT_LNG = "TEMP_LAT_LNG"

export const API_LAT_LNG = "API_LAT_LNG"

export const CURR_LAT_LNG = "CURR_LAT_LNG"

export const MAP_FULL_SCREEN = "MAP_FULL_SCREEN"

export const CT_SOURCE = "CT_SOURCE"