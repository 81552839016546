import getConfig from 'next/config';

const {publicRuntimeConfig } = getConfig()
const Config = {
  BASE_API_URL:publicRuntimeConfig.baseAPIPath,
  BASE_BUILD_API_URL:publicRuntimeConfig.baseBuildPath,
  ASSETPREFIX_PATH: publicRuntimeConfig.ASSETPREFIX,
  DEBUG: publicRuntimeConfig.DEBUG,
  DEFAULT_HEADER: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  },
  HUMAN_READABLE_DATE_FORMAT: "DD MMM, YYYY",
  gtmCode: publicRuntimeConfig.gtmCode,
  BASE_URL:publicRuntimeConfig.BASE_URL,
  instaPayLater:publicRuntimeConfig.instaPayLater,
  homecarePremuim:publicRuntimeConfig.homecarePremuim,
  CLEVER_TAP_ACCOUNT_ID: publicRuntimeConfig.CLEVER_TAP_ACCOUNT_ID,
  CRITEO_SALT: publicRuntimeConfig.CRITEO_SALT,
  GOOGLE_MAP_KEY: publicRuntimeConfig.GOOGLE_MAP_KEY,
  CLARITY_ACCOUNT_ID: publicRuntimeConfig.CLARITY_ACCOUNT_ID,
}

export default Config;
