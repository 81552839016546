import Config from '../../config';
import { GetCall, PostCall, GetCallWithErrorDetails } from '../helper'

const BASE_API_URL = Config.BASE_API_URL
const DEFAULT_HEADER = Config.DEFAULT_HEADER

const URLS = {
    "SEND_OTP": BASE_API_URL + "/v3/otp/send_otp",
    "SEND_VOICE_OTP":Config.BASE_API_URL + "/v3/otp/send_voice_otp",
    "VERIFY_OTP": BASE_API_URL + "/v3/otp/verify_otp",
    "GET_PLANS": BASE_API_URL + "/v3/selfserve/order_items",
    "GET_PLAN_DETAILS": BASE_API_URL + "/v3/selfserve/order_item",
    "GET_PLAN_COVERS": BASE_API_URL + "/v3/selfserve/plan_covers",
    "GET_PINCODE_DETAILS": BASE_API_URL + "/v3/selfserve/sr_pincode_detail",
    "GET_CATEGORY_ISSUES": BASE_API_URL + "/v3/selfserve/category_issues",
    "CREATE_TICKET": BASE_API_URL + "/v3/selfserve/create_ticket",
    "GET_TICKET":BASE_API_URL+"/v3/disclaimer/get_ticket",
    "COPAY_CHECKOUT": BASE_API_URL + "/v3/selfserve/copay_checkout",
    "COPAY_SUCCESS": BASE_API_URL + "/v3/selfserve/copay_success",
    "WRITE_TO_US": BASE_API_URL + "/selfserve/create_incident_emailterminator",
    "IS_RESTRICTED": BASE_API_URL + "/selfserve/is_selfserve_restricted",
    "SLOT_AVAILIBILITY": BASE_API_URL + "/crm/slot_availability",
    "GET_CATEGORY_DETAILS": BASE_API_URL + "/v3/selfserve/category_details",
    "GET_COPAYMENT_DETAILS": BASE_API_URL + "/v3/copay/get_amount"
}

export const SendOTP = async (data: any) => {
    return await PostCall(URLS.SEND_OTP, data, DEFAULT_HEADER);
}

export const VerifyOTP = async(data: any) => {
    return await PostCall(URLS.VERIFY_OTP, data, DEFAULT_HEADER);
}

export const GetPlans = async (data: any) => {
    return await GetCall(URLS.GET_PLANS, data, DEFAULT_HEADER)
}

export const GetPlanDetails = async (id, data: any) => {
    return await GetCall(`${URLS.GET_PLAN_DETAILS}/${id}`, data, DEFAULT_HEADER)
}

export const GetIsRestricted = async (data?: any) => {
    return await GetCall(URLS.IS_RESTRICTED, data, DEFAULT_HEADER)
}

export const GetPlanCovers = async (data: any) => {
    return await GetCall(URLS.GET_PLAN_COVERS, data, DEFAULT_HEADER)
}

export const GetPincodeDetails = async (data: any) => {
    return await GetCall(URLS.GET_PINCODE_DETAILS, data, DEFAULT_HEADER)
}

export const GetCategoryIssues = async (data: any) => {
    return await GetCall(URLS.GET_CATEGORY_ISSUES, data, DEFAULT_HEADER)
}

export const CreateTicket = async (data: any) => {
    return await PostCall(URLS.CREATE_TICKET, data, DEFAULT_HEADER)
}

export const GetTicketDetails = async (data) => {
    return await GetCall(URLS.GET_TICKET,data,DEFAULT_HEADER)
}

export const CopayCheckout = async (data: any) => {
    return await PostCall(URLS.COPAY_CHECKOUT, data, DEFAULT_HEADER)
}

export const CopaySuccess = async (data: any) => {
    return await PostCall(URLS.COPAY_SUCCESS, data, DEFAULT_HEADER)
}

export const WriteToUs = async (data: any) => {
    return await PostCall(URLS.WRITE_TO_US, data, DEFAULT_HEADER)
}

export const GetSlotAvailabilityData = async (data?: any) => {
    return await GetCallWithErrorDetails(URLS.SLOT_AVAILIBILITY, data, DEFAULT_HEADER)
}

export const GetCategoryDetails = async (data: any) => {
    return await GetCall(URLS.GET_CATEGORY_DETAILS, data, DEFAULT_HEADER)
}

export const GetCopaymentDetails = async (data: any) => {
    return await GetCall(URLS.GET_COPAYMENT_DETAILS, data, DEFAULT_HEADER)
}
