import React from 'react';
import {connect} from 'react-redux';
import {hideQuickAlert} from '../../redux/actions/genericActions'

class QuickAlert extends React.Component<any> {
  timer:any = null;
  setHiding = (message) =>{
    if(this.timer){
      clearTimeout(this.timer)
    }
    this.timer = setTimeout(()=>{
      this.props.hideQuickAlert()
    }, message.hideAfter?  message.hideAfter : 3000)
  }

  render(){
    this.props.message && this.setHiding(this.props.message)
    let className = ""
    if(!!this.props.message){
      className = "quick-alert--show"
      if(this.props.message.type){
        className = `${className} quick-alert--${this.props.message.type}`
      }
    }
    return (
      <div className={`error_toast ${className}`}>
        {this.props.message && this.props.message.text}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
    return {
        message: state.genericReducer.message
    }
}
const mapDispatchToProps = dispatch => ({
    hideQuickAlert: () => dispatch(hideQuickAlert())
})
  
export default connect(mapStateToProps, mapDispatchToProps)(QuickAlert)