export const ADD_TO_CART = "ADD_TO_CART"

export const UPDATE_CART = "UPDATE_CART"

export const UPDATE_CART_ERRORS = "UPDATE_CART_ERRORS"

export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM"

export const TOGGLE_WIDGET = "TOGGLE_WIDGET"

export const ERROR = "ERROR"

export const VERIFY_OTP = "VERIFY_OTP"

export const COMPLETE_PERSONAL_DETAILS = "COMPLETE_PERSONAL_DETAILS"

export const UNSET_VERIFIED_PHONE = "UNSET_VERIFIED_PHONE"

export const DELETE_CART = "DELETE_CART"

export const CHECK_EXISTING_CART = "CHECK_EXISTING_CART"

export const UPDATE_FRONTEND_CART = 'UPDATE_FRONTEND_CART'

export const CHECKOUT_SUCCESS_STATUS = 'CHECKOUT_SUCCESS_STATUS'

export const PAYMENT_STATUS = "PAYMENT_STATUS"

export const APPLY_DISCOUNT = "APPLY_DISCOUNT"

export const DISCOUNT_ERROR = "DISCOUNT_ERROR"

export const REMOVE_DISCOUNT = "REMOVE_DISCOUNT"

export const TOGGLE_CART_ALERT = "TOGGLE_CART_ALERT"

export const SET_CART_TAB_CHANGE = "SET_CART_TAB_CHANGE"

export const TOGGLE_SCROLL_TOP = "TOGGLE_SCROLL_TOP"

export const TOGGLE_RESET_STEP = "TOGGLE_RESET_STEP"

export const TOGGLE_REDIRECTION = "TOGGLE_REDIRECTION"

export const CHANGE_UPSELL_CROSS_SELL_MODAL = "CHANGE_UPSELL_CROSS_SELL_MODAL"

export const UPDATE_DEVICE_DETAIL = "UPDATE_DEVICE_DETAIL"

export const UPDATE_CART_ITEM = "UPDATE_CART_ITEM"

export const SERVICE_MODAL_TOGGLE = "SERVICE_MODAL_TOGGLE"

export const UPDATE_SERVICEABLE = "UPDATE_SERVICEABLE"

export const TOGGLE_LOADING = "TOGGLE LOADING"

export const SET_CUSTOMER_DETAILS = "SET_CUSTOMER_DETAILS"

export const SET_DISCOUNT_ERROR = "SET_DISCOUNT_ERROR"

export const RESET_MODAL = "RESET_MODAL"

export const OTP_VALID = "OTP_VALID"

export const PERSONAL_DETAILS_VALID = "PERSONAL_DETAILS_VALID"

export const IS_ADDRESS_VALID = "IS_ADDRESS_VALID"

export const SLOT_SELECTION_VALID = "SLOT_SELECTION_VALID"

export const SLOT_SELECTION_SHOWN = "SLOT_SELECTION_SHOWN"

export const UPDATE_PINCODE = "UPDATE_PINCODE"

export const UPDATED_SLOT_DISABLE = "UPDATED_SLOT_DISABLE"

export const UPDATED_SLOT_DATA = "UPDATED_SLOT_DATA"

export const SLOT_LOADING="SLOT_LOADING"

export const SLOT_SHOWN="SLOT_SHOWN"

export const SLOT_API_COUNT="SLOT_API_COUNT"

export const SLOT_INSTA_ITEM="SLOT_INSTA_ITEM"

export const IS_CART_VALID = "IS_CART_VALID"

export const TOGGLE_CART_ALERT_AGENT = "TOGGLE_CART_ALERT_AGENT"

export const SHOW_TOAST = "SHOW_TOAST"

export const ADD_SLOT_SELECTION_DATA = "ADD_SLOT_SELECTION_DATA"

export const UPDATE_SLOT_SELECTION_DATA = "UPDATE_SLOT_SELECTION_DATA"

export const UPDATE_ADDRESS_ID = "UPDATE_ADDRESS_ID"

export const CLEAR_CART_TYPE = "CLEAR_CART_TYPE"

export const UPDATE_VERIFY_MODE = "UPDATE_VERIFY_MODE"

export const HAS_PAGE_LOADED = "HAS_PAGE_LOADED"
