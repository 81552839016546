import { combineReducers } from 'redux';
import cartReducer from "./cartReducer";
import activationReducer from "./activationReducer";
import planReducer from "./planReducer";
import instarepairReducer from "./instaReducer";
import orderReducer from "./orderReducer";
import genericReducer from "./genericReducer";
import selfserveReducer from './selfserveReducer';
import trackerReducer from './trackerReducer';
import profileReducer from './profileReducer';
import customerReducer from './customerReducer';
import myaccountReducer from './myaccountReducer';

const reducers = combineReducers({
  plans:planReducer,
  cart:cartReducer,
  instarepairplans: instarepairReducer,
  activation: activationReducer,
  orders: orderReducer,
  genericReducer: genericReducer,
  profile: profileReducer,
  selfserveReducer: selfserveReducer,
  trackerReducer: trackerReducer,
  customerReducer: customerReducer,
  myaccountReducer: myaccountReducer
})

const rootReducer = (state:any, action:any) => {
  return reducers(state, action);
}

export default rootReducer;
