import * as cartActionTypes from "../actionTypes/cartActionTypes";
import {
  CreateCart,
  ApplyDiscount,
  RemoveDiscount,
  AddToCart,
  RemoveCartItem,
  PatchCartItem,
  UpdateDeviceDetails,
  GetAvailableSlot,
} from "../../api/cart";
import { addToCartEvent, discountAppliedSuccess, removeFromCart } from "../../tracking/ecommerceTracking";
import {
  couponCodeAppliedFailed,
  couponCodeAppliedSuccess,
} from "../../tracking/segment/checkoutPage";
import { getUtmParams } from "../../utils";
import store from "..";
import Cookies from 'js-cookie';

export const removeItem = (data: any, quantity, force = false) => {
  
  return (dispatch, getState) => {
    const state = getState();
    if (
      state.cart.items.length == 1 &&
      (state.cart.quantity == 1 || data.remove) &&
      !force
    ) {
      dispatch({
        type: cartActionTypes.TOGGLE_CART_ALERT,
        data: {
          toggle: true,
          toggleType: "cartRemove",
          data: {
            data: data,
            quantity: quantity,
          },
        },
      });
    } else {
      if(data.source==='decrementQuantity'){
        removeFromCart(data.data)
      }
      else{
        removeFromCart(data)
      }
      if (data.quantity - quantity == 0) {
        Cookies.remove('website_cartToken')
        RemoveCartItem(data.id, data).then(() => {
          dispatch({
            type: cartActionTypes.REMOVE_CART_ITEM,
            item_id: data.id,
          });
        });
      } else {
        data["quantity"] = data["quantity"] - 1;
        PatchCartItem(data.id, data).then((res: any) => {
          dispatch({
            type: cartActionTypes.UPDATE_CART_ITEM,
            item: res.data,
          });
        });
      }
    }
  };
};

export const serviceModalToggle = (show) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.SERVICE_MODAL_TOGGLE,
      show: show,
    });
  };
};

export const updateDeviceDetail = (device_detail) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.UPDATE_DEVICE_DETAIL,
      device_detail: device_detail,
    });
  };
};

export const updateServiceable = (data, show_pop_up = false) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.UPDATE_SERVICEABLE,
      data: data,
      show_pop_up: show_pop_up,
    });
  };
};

export const updateCartError = (errors) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.UPDATE_CART_ERRORS,
      errors: errors,
    });
  };
};


export const updateCartItem = (item) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.UPDATE_CART_ITEM,
      item: item,
    });
  };
};

export const patchCartItem = (id, data) => {
  return (dispatch) => {
    PatchCartItem(id, data).then((res: any) => {
      addToCartEvent(res.data)
      Cookies.set('website_cartToken', res.data.token_id)
      dispatch({
        type: cartActionTypes.UPDATE_CART_ITEM,
        item: res.data,
      });
    });
  };
};

export const addToCart = (data: any, setResponse?: any) => {
  const allowedObj = {
    amc: "amc,insta-repair, total-protection",
    "insta-repair": "insta-repair, amc, total-protection",
    "protection-plan": "protection-plan",
    homecare: "homecare",
  };
  return (dispatch, getState) => {
    const state = getState();
    Cookies.set('website_cartToken',state.cart.token_id)
    setResponse && setResponse(true)
    if (state.cart.type && !allowedObj[state.cart.type].includes(data.type) && !data.clear) {
      setResponse && setResponse(false)
      dispatch({
        type: cartActionTypes.TOGGLE_CART_ALERT,
        data: {
          toggle: true,
          toggleType: "cartAdd",
          data,
        },
      });
    } else {
      if (!state.cart.token_id) {
        CreateCart(data)
          .then((res: any) => {
            Cookies.set('website_cartToken',res.data.token_id)
            dispatch({
              type: cartActionTypes.CHECK_EXISTING_CART,
              data: res.data,
              redirectToCart: true,
              showToast: true,
            });
            setResponse && setResponse(false);
            addToCartEvent(
              res.data.items.filter((value) => value.sku == data.item.sku)[0]
            ); // GTM TRIGGER - add To Cart Event
          })
          .catch((error: any) => {
            console.log(error)
            dispatch({
              type: cartActionTypes.CHECK_EXISTING_CART,
              data: {},
            });
          });
      } else {
        if (data.items !== undefined) {
          UpdateDeviceDetails(data)
            .then((res: any) => {
              dispatch({
                type: cartActionTypes.CHECK_EXISTING_CART,
                data: res.data,
                redirectToCart:
                  data.redirect != undefined ? data.redirect : true,
                showToast: true,
              });
              dispatch({
                type: cartActionTypes.CHANGE_UPSELL_CROSS_SELL_MODAL,
                show: false,
              });
              setResponse && setResponse(false);
              addToCartEvent(
                res.data.items.filter((value) => value.sku == data.item.sku)[0]
              ); // GTM TRIGGER - add To Cart Event
            })
            .catch((error: any) => {
              console.log(error)
              dispatch({
                type: cartActionTypes.CHECK_EXISTING_CART,
                data: {},
              });
            });
        } else {
          AddToCart(data)
            .then((res: any) => {
              dispatch({
                type: cartActionTypes.CHECK_EXISTING_CART,
                data: res.data,
                redirectToCart:
                  data.redirect != undefined ? data.redirect : true,
                showToast: true,
              });
              if (data.type == "insta-repair") {
                dispatch({
                  type: cartActionTypes.CHANGE_UPSELL_CROSS_SELL_MODAL,
                  show: true,
                });
              } else {
                dispatch({
                  type: cartActionTypes.CHANGE_UPSELL_CROSS_SELL_MODAL,
                  show: false,
                });
              }
              setResponse && setResponse(false);
              addToCartEvent(
                res.data.items.filter((value) => value.sku == data.item.sku)[0]
              ); // GTM TRIGGER - add To Cart Event
            })
            .catch((error: any) => {
              console.log(error)
              dispatch({
                type: cartActionTypes.CHECK_EXISTING_CART,
                data: {},
              });
            });
        }
      }
    }
  };
};

export const toggleWidget = () => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.TOGGLE_WIDGET,
    });
  };
};

export const setVerifiedNumber = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.VERIFY_OTP,
      data: data,
    });
  };
};

export const validateForm = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.COMPLETE_PERSONAL_DETAILS,
      data: data,
    });
  };
};

export const unsetVerifiedPhone = () => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.UNSET_VERIFIED_PHONE,
    });
  };
};

export const checkoutSuccessStatus = (data: any, details: any) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.CHECKOUT_SUCCESS_STATUS,
      data: data,
      details: details,
    });
  };
};

export const deleteCart = (preserveType: boolean) => {
  Cookies.remove('website_cartToken')
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.DELETE_CART,
      preserveType: preserveType,
    });
  };
};

export const checkExistingCart = (data: any) => {
  let error_messsage=store.getState().cart.discount_alert_message
  data.error_messsage=error_messsage
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.CHECK_EXISTING_CART,
      data: data,
    });
  };
};

export const clearCartType = () => {
  Cookies.remove('website_cartToken')
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.CLEAR_CART_TYPE
    });
  };
};

export const updateFrontendCart = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.UPDATE_FRONTEND_CART,
      data: data,
    });
  };
};

export const paymentStatus = (data: string) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.PAYMENT_STATUS,
      data: data,
    });
  };
};

export const applyDiscount = (data: any) => {
  return (dispatch) => {
    ApplyDiscount(data)
      .then((res: any) => {
        res.data.discount_alert_message = res.data.discount_message
        dispatch({
          type: cartActionTypes.CHECK_EXISTING_CART,
          data: res.data,
          loading: "false",
        });
        if (res.data.discount_message.is_valid) {
          couponCodeAppliedSuccess({
            code: res.data.discount_code,
            discount_amount: res.data.discount_amount,
            status: "success",
            ...getUtmParams(),
          });
          discountAppliedSuccess(res.data, res.data?.token_id)
        } else {
          couponCodeAppliedFailed({
            code: data.data.discount_code,
            status: "failed",
            ...getUtmParams(),
          });
        }
      })
      .catch((err: any) => {
        console.log("Error: ", err);
      });
  };
};

export const removeDiscount = (data: any) => {
  return (dispatch) => {
    RemoveDiscount(data)
      .then((res: any) => {
        dispatch({
          type: cartActionTypes.CHECK_EXISTING_CART,
          data: res.data,
        });
      })
      .catch((err: any) => {
        console.log(err);
      });
  };
};

export const setDiscountMessage = (discount_message: any) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.SET_DISCOUNT_ERROR,
      discount_message: discount_message,
    });
  };
};
export const toggleCartAlert = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.TOGGLE_CART_ALERT,
      data: data,
    });
  };
};

export const toggleScrollTop = (data: boolean) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.TOGGLE_SCROLL_TOP,
      data: data,
    });
  };
};

export const toggleResetStep = (data: boolean) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.TOGGLE_RESET_STEP,
      data: data,
    });
  };
};

export const toggleRedirection = () => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.TOGGLE_REDIRECTION,
    });
  };
};

export const changeUpsellCrossSellModal = (show) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.CHANGE_UPSELL_CROSS_SELL_MODAL,
      show: show,
    });
  };
};

export const toggleLoading = (toggle: string) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.TOGGLE_LOADING,
      toggle: toggle,
    });
  };
};

export const setCustomerDetailsRedux = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.SET_CUSTOMER_DETAILS,
      data: data,
    });
  };
};

export const resetModal = (reset: boolean) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.RESET_MODAL,
      reset: reset,
    });
  };
};

export const otpValid = (data: boolean) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.OTP_VALID,
      data: data,
    });
  };
};

export const personalDetailsValid = (data: boolean) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.PERSONAL_DETAILS_VALID,
      data: data,
    });
  };
};

export const isAddressValid = (data: boolean) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.IS_ADDRESS_VALID,
      data: data,
    });
  };
};

export const slotSelectionValid = (data: boolean) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.SLOT_SELECTION_VALID,
      data: data,
    });
  };
};

export const slotSelectionShown = (data: boolean) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.SLOT_SELECTION_SHOWN,
      data: data,
    });
  };
};

export const cartValidRedux = (data: boolean) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.IS_CART_VALID,
      data: data,
    });
  };
};

export const cartAlertAgent = (data) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.TOGGLE_CART_ALERT_AGENT,
      data: {
        toggle: true,
        toggleType: "cartAlertAgent",
        cartAlertData: data
      },
    });
  };
};
export const updatePincode = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.UPDATE_PINCODE,
      data: data,
    });
  };
};

export const showToast = (data: boolean) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.SHOW_TOAST,
      data: data,
    });
  };
};

export const updateIsDisable = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.UPDATED_SLOT_DISABLE,
      data: data,
    });
  };
};

export const updateSlotData = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.UPDATED_SLOT_DATA,
      data: data,
    });
  };
};

export const updateLoading = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.SLOT_LOADING,
      data: data,
    });
  };
};

export const slotShow = (data: any) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.SLOT_SHOWN,
      data: data,
    });
  };
};

export const setSlotApiCount = (data: number) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.SLOT_API_COUNT,
      data: data,
    });
  };
};

export const setinstaCount = (data: number) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.SLOT_INSTA_ITEM,
      data: data,
    });
  };
};

const formatSlotData = (res) => {
  return Object.entries(res).reduce(
    (allSlots, slot: any) => {
      allSlots.slots[slot[0]] = {
        timings: slot[1],
        isTimeAvailable: slot[1].some((timings) => timings.is_available)
          ? true
          : false,
      };
      if (allSlots.slots[slot[0]]["isTimeAvailable"]) {
        allSlots.isDateAvailable = true;
      }
      return allSlots;
    },
    { isDateAvailable: false, slots: {} }
  );
};

export const fetchSlotData = (data: { payLoad: any; items: any }) => {
  return (dispatch) => {
    GetAvailableSlot(data.payLoad)
      .then((res: any) => {
        //const hardRes = {"2022-09-21":[{"is_available":false,"slot_time":"2 PM - 6 PM"},{"is_available":false,"slot_time":"10 AM - 2 PM"}],"2022-09-22":[{"is_available":false,"slot_time":"2 PM - 6 PM"},{"is_available":false,"slot_time":"10 AM - 2 PM"}],"2022-09-23":[{"is_available":false,"slot_time":"2 PM - 6 PM"},{"is_available":false,"slot_time":"10 AM - 2 PM"}],"2022-09-24":[{"is_available":false,"slot_time":"2 PM - 6 PM"},{"is_available":false,"slot_time":"10 AM - 2 PM"}],"2022-09-25":[{"is_available":false,"slot_time":"2 PM - 6 PM"},{"is_available":false,"slot_time":"10 AM - 2 PM"}],"2022-09-26":[{"is_available":false,"slot_time":"2 PM - 6 PM"},{"is_available":false,"slot_time":"10 AM - 2 PM"}],"2022-09-27":[{"is_available":false,"slot_time":"2 PM - 6 PM"},{"is_available":false,"slot_time":"10 AM - 2 PM"}]}
        if (res.response.ok) {
          dispatch({
            type: cartActionTypes.ADD_SLOT_SELECTION_DATA,
            data: {items: data.items, ...formatSlotData(res.data)}
          });
        } else{
          dispatch({
            type: cartActionTypes.ADD_SLOT_SELECTION_DATA,
            data: {items: data.items, slots: {}, isDateAvailable: false}
          });
        }
      })
      .catch((err: any) => {
        console.log(err);
        // const hardRes = {"2022-10-31":[{"is_available":false,"slot_time":"10 AM - 2 PM"},{"is_available":false,"slot_time":"2 PM - 6 PM"}],"2022-11-01":[{"is_available":false,"slot_time":"10 AM - 2 PM"},{"is_available":false,"slot_time":"2 PM - 6 PM"}],"2022-11-02":[{"is_available":false,"slot_time":"10 AM - 2 PM"},{"is_available":false,"slot_time":"2 PM - 6 PM"}],"2022-11-03":[{"is_available":false,"slot_time":"10 AM - 2 PM"},{"is_available":true,"slot_time":"2 PM - 6 PM"}],"2022-11-04":[{"is_available":true,"slot_time":"10 AM - 2 PM"},{"is_available":true,"slot_time":"2 PM - 6 PM"}],"2022-11-05":[{"is_available":true,"slot_time":"10 AM - 2 PM"},{"is_available":true,"slot_time":"2 PM - 6 PM"}],"2022-11-06":[{"is_available":true,"slot_time":"10 AM - 2 PM"},{"is_available":true,"slot_time":"2 PM - 6 PM"}],"2022-11-07":[{"is_available":true,"slot_time":"10 AM - 2 PM"},{"is_available":true,"slot_time":"2 PM - 6 PM"}]}
        dispatch({
          type: cartActionTypes.ADD_SLOT_SELECTION_DATA,
          data: {items: data.items, slots: {}, isDateAvailable: false}
        });
      });
  };
};

export const updateSlotSelectionData = (data) => {
  console.log(data);  
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.UPDATE_SLOT_SELECTION_DATA,
      data: data
    })
  }
}

export const updateAddressID = (data) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.UPDATE_ADDRESS_ID,
      data: data
    })
  }
}

export const updateVerify = (data) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.UPDATE_VERIFY_MODE,
      data: data
    })
  }
}

export const hasPageLoaded = (data) => {
  return (dispatch) => {
    dispatch({
      type: cartActionTypes.HAS_PAGE_LOADED,
      data: data
    })
  }
}
